import React, { Component } from 'react'
import { Tag } from 'antd'
import { messageTypes } from '../../actions/messages'

class StyledStatusDisplay extends Component {
  constructor (props) {
    super(props)
    this.state = {
      color: '',
      text: ''
    }
  }

  componentDidMount () {
    this.describeButton(this.props.status)
  }

  componentDidUpdate (prevProps, prevState) {
    const { status } = this.props
    if (prevProps.status !== status) {
      this.describeButton(status)
    }
  }

  describeButton = (status) => {
    let color = ''
    let text = ''
    const { language } = this.props

    if (status === 'poweredOn') {
      color = 'green'
      text = messageTypes[language].status_poweredOn
    } else if (status === 'poweredOff') {
      color = 'red'
      text = messageTypes[language].status_poweredOff
    } else if (status === 'err') {
      color = 'black'
      text = messageTypes[language].status_err
    } else if (status === 'turning_on') {
      color = 'yellow'
      text = messageTypes[language].status_turning_on
    } else if (status === 'turning_off') {
      color = 'yellow'
      text = messageTypes[language].status_turning_off
    } else if (status === 'rebooting') {
      color = 'yellow'
      text = messageTypes[language].status_rebooting
    } else if (status === 'reverting') {
      color = 'yellow'
      text = messageTypes[language].status_reverting
    } else if (status === 'logging') {
      color = 'yellow'
      text = messageTypes[language].status_logging
    } else if (status === 'testing') {
      color = 'yellow'
      text = messageTypes[language].status_testing
    } else if (status === 'suspended') {
      color = 'yellow'
      text = messageTypes[language].status_suspended
    } else {
      color = 'pink'
      text = status
    }

    this.setState({
      color, text
    })
  }

  render () {
    return (
      <Tag color={this.state.color}>{this.state.text}</Tag>
    )
  }
}

export default StyledStatusDisplay

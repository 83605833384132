import { combineReducers } from 'redux'

const hdStore = (state = {}, action) => {
  switch (action.type) {
    case 'SET_LANGUAGE':
      return {
        ...state,
        language: action.language
      }
    case 'SET_STACK_ACTION_STATUS':
      return {
        ...state,
        stackActionStatus: action.stackActionStatus
      }
    case 'SET_SEARCH_PASSPHRASE':
      return {
        ...state,
        searchPassphrase: action.searchPassphrase
      }
    case 'SET_SCENARIO_ELEMENT':
      return {
        ...state,
        scenarioElement: action.scenarioElement
      }
    case 'SET_SCENARIO_MODAL_VISIBLE':
      return {
        ...state,
        scenarioModalVisible: action.scenarioModalVisible
      }
    case 'SET_UI_PATHNAME':
      return {
        ...state,
        uiPathname: action.uiPathname
      }
    case 'SET_QUIZ_ID':
      return {
        ...state,
        quizId: action.quizId
      }
    case 'SET_AUTH':
      return {
        ...state,
        auth: action.auth
      }
    case 'SET_USER':
      return {
        ...state,
        user: action.user
      }
    case 'SET_STACK':
      return {
        ...state,
        stack: action.stack
      }
    case 'SET_USER_STACKS':
      return {
        ...state,
        user_stacks: action.userStacks
      }
    case 'SET_CURRENT_STACK_TYPE':
      return {
        ...state,
        current_stack_type: action.currentStackType
      }
    case 'SET_CURRENT_STACK_MACHINES':
      return {
        ...state,
        current_stack_machines: action.currentStackMachines
      }
    case 'SET_STACK_VPNS':
      return {
        ...state,
        stackVpns: action.stackVpns
      }
    default:
      return state
  }
}

const reducers = combineReducers({
  hdStore
})

export default reducers

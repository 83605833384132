import React from 'react'
import { withRouter, Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'

import HDComponent from './components/common/hdComponent'
import StyledLoginLayout from './styledComponents/sections/login/loginLayout'
import StyledPureLayout from './styledComponents/common/pureLayout'
import StyledMainLayout from './styledComponents/common/mainLayout'

import Home from './components/sections/home/'
import Register from './components/sections/register/'
import ResetPasswordRequest from './components/sections/reset-password/requestReset'
import ResetPasswordSetPassword from './components/sections/reset-password/setPassword'

import Scenarios from './components/sections/scenarios/'
// import ScenarioFileViewer from './components/sections/scenarios/fileViewer';
// import Scenario from './components/sections/scenario';
import Machines from './components/sections/machines/'
import MachinesConsole from './components/sections/machines/console'
// import Machine from './components/sections/machine';
import Ranking from './components/sections/ranking/'
import Quiz from './components/sections/quiz/'

import Settings from './components/sections/settings/'
import MachinesSettings from './components/sections/settings/machines/'
import ScenariosSettings from './components/sections/settings/scenarios/'
import QuizSettings from './components/sections/settings/quiz/'
import UsersSettings from './components/sections/settings/users/'
import SessionsSettings from './components/sections/settings/sessions'
import LogsSettings from './components/sections/settings/logging/'
import VpnsSettings from './components/sections/settings/vpns/'
import VpnDescriptionsSettings from './components/sections/settings/vpnDescriptions/'
import StackDescriptionsSettings from './components/sections/settings/stackDescriptions/'
import MapObjectsSettings from './components/sections/settings/mapObjects/'
import StacksSettings from './components/sections/settings/stacks/'

import NoMatch from './components/sections/404'
import AccessDenied from './components/sections/401'
// const SubMenu = Menu.SubMenu;
// const MenuItemGroup = Menu.ItemGroup;

import config, { views } from './config/'

import { getCookie } from './actions/helpers'
import { setAuth, setUser, setLanguage } from './store/actions'

// define own route to allow setting layout
const AppRoute = ({ component: Component, layout: Layout, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout>
        {Component
          ? <Component {...props} />
          : ''}
      </Layout>
    )}
  />
)

class Routes extends HDComponent {
  componentDidMount () {
    const { setAuth, setUser } = this.props

    if (getCookie('_view') && getCookie('_token')) {
      setAuth(getCookie('_view'))
    } else if (getCookie('_token')) {
      setAuth(true)
    }

    const user = window.localStorage.getItem('user')
    if (user) {
      setUser(JSON.parse(user))
    }

    // set app language
    this.props.setLanguage(this.determineLanguage(window.localStorage.getItem('language')))
  }

  render () {
    const { auth } = this.props
    const userAuth = (auth === views.ad || auth === views.sa || auth === views.us || auth === views.ucr)
    const adminAuth = (auth === views.ad || auth === views.sa)
    const superAdminAuth = (auth === views.sa)

    return (
      <>
        {(!config.auth_handling || auth)
          ? (
            <Switch>
              <AppRoute exact path='/' component={Home} layout={StyledMainLayout} />
              <AppRoute exact path='/scenarios' component={(!config.auth_handling || auth) ? Scenarios : AccessDenied} layout={StyledMainLayout} />
              {/* <AppRoute exact path="/scenarios/file-viewer/:id/:file" component={(!config.auth_handling || auth) ? ScenarioFileViewer : AccessDenied} layout={StyledPureLayout} /> */}
              <AppRoute exact path='/machines/console/:vmware_id' component={(!config.auth_handling || auth) ? MachinesConsole : AccessDenied} layout={StyledPureLayout} />
              <AppRoute exact path='/machines' component={(!config.auth_handling || auth) ? Machines : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/ranking' component={userAuth ? Ranking : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings' component={adminAuth ? Settings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/tests' component={auth ? Quiz : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/users' component={adminAuth ? UsersSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/sessions' component={adminAuth ? SessionsSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/tests' component={superAdminAuth ? QuizSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/scenarios' component={superAdminAuth ? ScenariosSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/machines' component={superAdminAuth ? MachinesSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/logs' component={adminAuth ? LogsSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/vpns' component={superAdminAuth ? VpnsSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/vpn_descriptions' component={superAdminAuth ? VpnDescriptionsSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/stack_descriptions' component={superAdminAuth ? StackDescriptionsSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/map_objects' component={superAdminAuth ? MapObjectsSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute exact path='/settings/stacks' component={superAdminAuth ? StacksSettings : AccessDenied} layout={StyledMainLayout} />
              <AppRoute component={NoMatch} layout={StyledMainLayout} />
            </Switch>
            )
          : (
            <Switch>
              {config.auth_handling && config.registration &&
                <AppRoute exact path='/register' component={Register} layout={StyledPureLayout} />}
              {config.auth_handling && config.password_reset &&
                <AppRoute exact path='/reset-password' component={ResetPasswordRequest} layout={StyledPureLayout} />}
              {config.auth_handling && config.password_reset &&
                <AppRoute exact path='/reset-password/:token' component={ResetPasswordSetPassword} layout={StyledPureLayout} />}
              <AppRoute layout={StyledLoginLayout} />
            </Switch>
            )}
      </>
    )
  }
}

const mapStateToProps = state => ({
  auth: state.hdStore.auth,
  language: state.hdStore.language
})

const mapDispatchToProps = {
  setAuth,
  setUser,
  setLanguage
}

const RoutesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Routes)

export default withRouter(RoutesContainer)

import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledQuizTableSettings from '../../../../styledComponents/sections/settings/quiz/quizTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import QuizModalSettings from './quizModal'
import { openNotificationPopup, getCookie, deleteElementWithIdFromArr } from '../../../../actions/helpers'
import {
  quizzesGetBuilder,
  quizzesCreateBuilder,
  quizzesUpdateBuilder,
  quizzesDeleteBuilder,
  scenariosGetBuilder
} from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'

class QuizSettings extends Component {
  state = {
    scenarios: [],
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  getAllData = () => {
    const { language } = this.props
    quizzesGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
    scenariosGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            scenarios: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props
    quizzesDeleteBuilder(id, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    quizzesUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false,
          dataId: null
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    quizzesCreateBuilder(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        this.setState({ isNewRecord: false })
        this.getAllData()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.scenario = val.scenario.name[lang]
      obj.data_length = val.data.length || 0
      obj.desc = val.desc[lang]
      obj.score = val.score
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { scenarios, data, dataId, isNewRecord } = this.state
    const { language } = this.props
    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_quiz}
        />
        <StyledQuizTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
        />
        <QuizModalSettings
          scenarios={scenarios}
          language={language}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const QuizSettingsContainer = connect(
  mapStateToProps
)(QuizSettings)

export default QuizSettingsContainer

import React, { Component } from 'react'
import StyledBody from '../../styledComponents/common/body'

class Body extends Component {
  render () {
    return (
      <StyledBody>
        {this.props.children}
      </StyledBody>
    )
  }
}

export default Body

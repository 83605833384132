import React, { Component } from 'react'
import { connect } from 'react-redux'
import { rankingGet } from '../../../actions'
import { messageTypes } from '../../../actions/messages'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import StyledRanking from '../../../styledComponents/sections/ranking/'

class Ranking extends Component {
  state = {
    ranking: [],
    columns: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getRanking()
  }

  setDataState (data) {
    const ranking = data.map((val, i) => {
      const main = {
        key: i + 1,
        name: val.name,
        tags: val.tags || [],
        score: val.score || 0
      }

      const flags = {}

      // prepare field with flags
      val.flag_data && val.flag_data.forEach((flag, i) => {
        flags['s' + i] = flag
      })

      return { ...main, ...flags }
    })

    return ranking
  }

  getRanking = () => {
    const { language } = this.props
    rankingGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            ranking: this.setDataState(json.response.ranking),
            columns: json.response.columns
          })
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            json.response[language],
            'frown')
        }
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { ranking, columns } = this.state
    const { language } = this.props
    return (
      <>
        <StyledRanking
          language={language}
          ranking={ranking}
          columns={columns}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const RankingContainer = connect(
  mapStateToProps
)(Ranking)

export default RankingContainer

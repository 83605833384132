import React, { Component } from 'react'
import { Layout } from 'antd'
const { Content } = Layout

class StyledBody extends Component {
  render () {
    return (
      <Content style={{ margin: '24px 16px 0', overflow: 'initial' }}>
        <div className='body-padding'>
          {this.props.children}
        </div>
      </Content>
    )
  }
}

export default StyledBody

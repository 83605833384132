import React from 'react'
import LoginForm from './loginForm'
import { connect } from 'react-redux'
import HDComponent from '../../../components/common/hdComponent'

class StyledLoginLayout extends HDComponent {
  constructor (props) {
    super(props)
    this.nameFieldRef = React.createRef()
  }

  componentDidMount () {
    const terminal = document.querySelector('.terminal')
    const hydra = document.querySelector('.hydra')
    const rebootSuccessText = document.querySelector('.hydra_reboot_success')
    const maxCharacters = 24
    const unloadedCharacter = '.'
    const loadedCharacter = '#'
    const spinnerFrames = ['/', '-', '\\', '|'];

    // Clone the element and give the glitch classes
    (glitchElement => {
      const glitch = glitchElement.cloneNode(true)
      const glitchReverse = glitchElement.cloneNode(true)
      glitch.classList.add('glitch--clone', 'glitch--bottom')
      glitchReverse.classList.add('glitch--clone', 'glitch--top')
      glitch.setAttribute('aria-hidden', 'true')
      glitchReverse.setAttribute('aria-hidden', 'true')

      glitchElement.insertAdjacentElement('afterend', glitch)
      glitchElement.insertAdjacentElement('afterend', glitchReverse)
    })(terminal)

    // Get all the loading bars
    const loadingBars = document.querySelectorAll('.loading-bar')
    const processAmounts = document.querySelectorAll('.process-amount')
    const spinners = document.querySelectorAll('.spinner')
    const rebootingText = document.querySelectorAll('.hydra_rebooting')
    const glitches = document.querySelectorAll('.glitch--clone')

    // Helper for random number
    const RandomNumber = (min, max) => Math.floor(Math.random() * max) + min

    const HideAll = elements =>
      elements.forEach(glitchGroup =>
        glitchGroup.forEach(element => element.classList.add('hidden')))

    const ShowAll = elements =>
      elements.forEach(glitchGroup =>
        glitchGroup.forEach(element => element.classList.remove('hidden')))

    // Render the bar to HTML
    const RenderBar = (values) => {
      const currentLoaded = values.lastIndexOf(loadedCharacter) + 1
      const loaded = values.slice(0, currentLoaded).join('')
      const unloaded = values.slice(currentLoaded).join('')

      // Update all the loading bars
      loadingBars.forEach(loadingBar => {
        loadingBar.innerHTML = `(${loaded}<span className="loading-bar--unloaded">${unloaded}</span>)`
      })

      // Update all the percentages
      const loadingPercent = Math.floor(currentLoaded / maxCharacters * 100)
      processAmounts.forEach(processAmount => {
        processAmount.innerText = loadingPercent
      })
    }

    // Update the loaded value and render it to HTML
    const DrawLoadingBar = (values) => {
      return new Promise((resolve) => {
        const loadingBarAnimation = setInterval(() => {
          if (!values.includes(unloadedCharacter)) {
            clearInterval(loadingBarAnimation)
            resolve()
          }

          values.pop(unloadedCharacter)
          values.unshift(loadedCharacter)
          RenderBar(values)
        }, RandomNumber(50, 70))
      })
    }

    const DrawSpinner = (spinnerFrame = 0) => {
      return setInterval(() => {
        spinnerFrame += 1
        spinners.forEach(
          spinner =>
            (spinner.innerText = `[${
         spinnerFrames[spinnerFrame % spinnerFrames.length]
        }]`)
        )
      }, RandomNumber(150, 200))
    }

    const AnimateBox = () => {
      const first = hydra.getBoundingClientRect()
      HideAll([spinners, glitches, rebootingText])
      rebootSuccessText.classList.remove('hidden')
      rebootSuccessText.style.visibility = 'hidden'
      const last = hydra.getBoundingClientRect()

      // if properties are 0 it means that login screen was hidden
      if (first.width === 0 && last.width === 0 && first.height === 0 && last.height === 0) {
        return
      }

      const hydraAnimation = hydra.animate([
        { transform: `scale(${first.width / last.width}, ${first.height / last.height})` },
        { transform: `scale(${first.width / last.width}, 1.2)` },
        { transform: 'none' }
      ], {
        duration: 600,
        easing: 'cubic-bezier(0,0,0.32,1)'
      })

      hydraAnimation.addEventListener('finish', () => {
        rebootSuccessText.removeAttribute('style')
        hydra.removeAttribute('style')

        // fire cancel animation to trigger name field focus properly
        hydraAnimation.cancel()
      })

      // focus name field when animation canceled
      const _this = this
      hydraAnimation.addEventListener('cancel', () => {
        _this.nameFieldRef.current.focus()
      })
    }

    const PlayHydra = async () => {
      terminal.classList.add('glitch')
      rebootSuccessText.classList.add('hidden')
      ShowAll([spinners, glitches, rebootingText])
      const loadingBar = new Array(maxCharacters).fill(unloadedCharacter)
      const spinnerInterval = DrawSpinner()

      // Play the loading bar
      await DrawLoadingBar(loadingBar)

      // Loading is complete on the next frame, hide spinner and glitch
      clearInterval(spinnerInterval)
      terminal.classList.remove('glitch')
      AnimateBox()
    }

    // play it!
    PlayHydra()
  }

  render () {
    const { language } = this.props

    return (

      <div className='hydra-container'>
        <div className='terminal'>
          <div className='scanline' />
          <p className='spinner hidden'>[/]</p>
          <div className='hydra'>
            <div className='hydra_rebooting hidden'>
              <p>&lt; HD Cloud engaged &gt;</p>
              <p className='text--sm'>Booting in progress</p>
              <p className='text--sm'>PROCESS: <span className='process-amount'>100</span>%</p>
              <p className='loading-bar'>(########################<span className='loading-bar--unloaded' />)</p>
            </div>
            <div className='hydra_reboot_success'>
              <p className='hydra-logo'>
                <img src='/images/logo_light_small.png' className='hydra-logo' alt='HD Logo' />
              </p>
              <font className='hydra-font'>
                <LoginForm
                  language={this.determineLanguage(language)} nameFieldRef={this.nameFieldRef}
                />
              </font>
              <p />
            </div>
          </div>
        </div>
        <div className='terminal glitch glitch--clone glitch--top hidden' aria-hidden='true'>
          <div className='scanline' />
          <p className='spinner hidden'>[/]</p>
          <div className='hydra'>
            <div className='hydra_rebooting hidden'>
              <p>&lt; HD Cloud engaged &gt;</p>
              <p className='text--sm'>Booting in progress</p>
              <p className='text--sm'>PROCESS: <span className='process-amount'>100</span>%</p>
              <p className='loading-bar'>(########################<span className='loading-bar--unloaded' />)</p>
            </div>
            <div className='hydra_reboot_success hidden'>
              <p>
                <img src='/images/logo_light_small.png' className='hydra-logo' alt='HD Logo' />
              </p>
              <form autoComplete='off' id='form1'>
                <font className='hydra-font'>
                  Username:<input type='text' name='username' />
                  Password:<input type='password' name='password' />
                </font>
              </form>
              <p />
            </div>
          </div>
        </div>
        <div className='terminal glitch glitch--clone glitch--bottom hidden' aria-hidden='true'>
          <div className='scanline' />
          <p className='spinner hidden'>[/]</p>
          <div className='hydra'>
            <div className='hydra_rebooting hidden'>
              <p>&lt; HD Cloud engaged &gt;</p>
              <p className='text--sm'>Booting in progress</p>
              <p className='text--sm'>PROCESS: <span className='process-amount'>100</span>%</p>
              <p className='loading-bar'>(########################<span className='loading-bar--unloaded' />)</p>
            </div>
            <div className='hydra_reboot_success hidden'>
              <p>
                <img src='/images/logo_light_small.png' className='hydra-logo' alt='HD Logo' />
              </p>
              <form autoComplete='off' id='form2'>
                <font className='hydra-font'>
                  Username:<input type='text' name='username' />
                  Password:<input type='password' name='password' />
                </font>
              </form>
              <p />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const LoginContainer = connect(
  mapStateToProps
)(StyledLoginLayout)

export default LoginContainer

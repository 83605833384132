import React from 'react'
import { connect } from 'react-redux'
import StyledUsersTable from '../../../../styledComponents/sections/settings/users/usersTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import UsersModalSettings from './usersModal'
import UsersModalDetails from './userDetailsModal'
import { openNotificationPopup, getCookie, deleteElementWithIdFromArr } from '../../../../actions/helpers'
import { usersGet, userDelete, userUpdate, userCreate, stacksGet } from '../../../../actions/'
import { messageTypes } from '../../../../actions/messages'
import settings, { views } from '../../../../config/'
import StackSwitchComponent from '../../../common/stackSwitchComponent'
import { /* setCurrentStackMachines, */ setStack } from '../../../../store/actions'

class UserSettings extends StackSwitchComponent {
  state = {
    data: [],
    dataId: null,
    showDataId: null,
    loading: false,
    isNewRecord: false,
    stacks: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
    this.getStacks()
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  setShowDataId = (showDataId) => { this.setState({ showDataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  returnWithKey (data) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.name = val.name
      obj.email = val.email
      const role = settings.roles.find(x => x.role === val.role)
      obj.role = role !== undefined ? messageTypes[this.props.language]['role_' + role.name] : ''
      obj.stack = val.stack !== undefined && val.stack !== null ? this.state.stacks.find(x => x._id === val.stack) : undefined

      retArr.push(obj)
    })
    return retArr
  }

  getAllData = () => {
    const { language } = this.props
    usersGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props
    userDelete(id, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })

    userUpdate(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({ loading: false })
        if (json.status === 'ok') {
          this.getAllData()
          this.setState({
            dataId: null
          })
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  getStacks = () => {
    const { language } = this.props
    stacksGet(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            stacks: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response && json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (values) => {
    const { language } = this.props
    this.setState({ loading: true })
    const { name, email, password, password_repeat: passwordRepeat, stack, role, create_new_stack: createNewStack, stacks, new_stack_type: newStackType } = values

    const data = {
      name,
      email,
      password,
      password_repeat: passwordRepeat,
      stack,
      stacks,
      role,
      create_new_stack: createNewStack,
      new_stack_type: newStackType
    }
    userCreate(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        this.setState({ isNewRecord: false })
        this.getAllData()

        // if create new stack option was checked, refresh stack type list
        if (values.new_stack_type) {
          this.getStacks()
        }
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { language, auth } = this.props
    const { data, dataId, showDataId, isNewRecord, stacks } = this.state
    const superAdminAuth = (auth === views.sa)

    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_user}
        />
        <StyledUsersTable
          language={language}
          data={this.returnWithKey(data)}
          setDataId={this.setDataId}
          setShowDataId={this.setShowDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
          switchToStack={this.switchStack}
          stack={this.props.stack}
        />
        <UsersModalSettings
          language={language}
          data={data}
          stacks={stacks}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
        />
        <UsersModalDetails
          language={language}
          data={data}
          stacks={stacks}
          dataId={showDataId}
          setShowDataId={this.setShowDataId}
          isSuperAdmin={superAdminAuth}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  stack: state.hdStore.stack,
  auth: state.hdStore.auth
})

const mapDispatchToProps = {
  setStack
}

const UserSettingsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings)

export default UserSettingsContainer

import React from 'react'
import HDComponent from '../../../components/common/hdComponent'
import { Row, Col } from 'antd'
import RegisterForm from './registerForm'

class StyledRegisterForm extends HDComponent {
  render () {
    const { language } = this.props
    return (
      <Row>
        <Col lg={{ span: 8 }} md={{ span: 0 }} />
        <Col lg={{ span: 8 }} md={{ span: 24 }}>
          <div className='register-logo'>
            <img src='/images/logo_light_small.png' alt='HD Logo' />
          </div>
          <RegisterForm
            language={this.determineLanguage(language)}
          />
        </Col>
        <Col lg={{ span: 8 }} md={{ span: 0 }} />
      </Row>
    )
  }
}

export default StyledRegisterForm

import React, { Component } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import Routes from './routes'

// import logo from './logo.svg';

// styles for nice scrollbar
import 'react-perfect-scrollbar/dist/css/styles.css'
// import antd styles
import 'antd/dist/antd.css'
// here are modifications of pure antd styles
import './assets/scss/antd-theme-custom.scss'
import './assets/scss/style.scss'
import './assets/scss/loginStyle.scss'

class App extends Component {
  render () {
    return (
      <div className='App'>
        <Router>
          <Routes />
        </Router>
      </div>
    )
  }
}

export default App

import React, { Component } from 'react'
import { Row, Col, Divider, Popover } from 'antd'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../../actions/messages.js'
class StyledMachineInfo extends Component {
  render () {
    const { language, name, status, ip } = this.props

    const content = (
      <div className='popover-content'>
        {
          status &&
            <p>Status: {messageTypes[language]['status_' + status]}</p>
        }
        {
          ip && ip.ipv4 &&
            <p>IPv4: {ip.ipv4}</p>
        }
        {
          ip && ip.ipv6 &&
            <p>IPv6: {ip.ipv6}</p>
        }
      </div>
    )

    return (
      <Row className='net-visualiser'>
        <Col span={24}>
          <Popover content={content} title={name}>
            <FontAwesomeIcon className={`server-ico status-${status}`} name='server' size='4x' />
          </Popover>
          <h3 className='m-name'>{name}</h3>
        </Col>
      </Row>
    )
  }
}

class StyledNetVisualiser extends Component {
  render () {
    const { net, language } = this.props

    return (
      <div>
        <Divider>{messageTypes[language].scenario_machines_status}</Divider>
        <Row gutter={8}>
          {
            net && net.length && net.map((val, i) =>
              <Col key={i} span={24} sm={8}>
                <StyledMachineInfo
                  id={val.id}
                  name={val.name[language]}
                  status={val.resources.status}
                  language={language}
                  ip={val.resources.ip}
                />
              </Col>
            )
          }
        </Row>
      </div>
    )
  }
}

export default StyledNetVisualiser

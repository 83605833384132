import React, { Component } from 'react'
import { Table, Input, Button } from 'antd'
import { SearchOutlined, FlagOutlined } from '@ant-design/icons'
import { messageTypes } from '../../../actions/messages'
import Highlighter from 'react-highlight-words'

class StyledRanking extends Component {
  state = {
    columns: []
  }

  // ###########################################
  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  // ###########################################

  setColumns () {
    const { language } = this.props

    const columns = [{
      title: messageTypes[language].place,
      dataIndex: 'key',
      key: 'place',
      className: 'scoreboard-static-cell',
      render: (number) => (
        number >= 1 && number <= 3 ? <><img src={'/images/' + number + '.png'} alt={number} /></> : number
      )
    }, {
      title: messageTypes[language].team,
      dataIndex: 'name',
      key: 'name',
      className: 'scoreboard-static-cell'
    }, {
      title: messageTypes[language].score,
      dataIndex: 'score',
      key: 'score',
      className: 'scoreboard-static-cell'
    }]

    let x = 0

    // prepare dynamic columns with flags
    this.props.columns && this.props.columns.forEach((column, i) => {
      const childs = []

      if (column.flags && column.flags.length > 0) {
        column.flags.forEach((flag, i) => {
          childs.push(
            {
              title: flag.name + ' (' + flag.current_value + ')',
              dataIndex: 's' + x,
              key: 's' + x,
              className: 'scoreboard-flag-cell' + (i === column.flags.length - 1 ? ' scoreboard-flag-cell-last' : ''),
              onHeaderCell: (column) => {
                return {
                  title: messageTypes[language].done + ': ' + flag.solved_count
                }
              },
              render: (done) => (
                done ? <FlagOutlined /> : ''
              )
            })
          x += 1
        })

        columns.push({
          title: column.name,
          children: childs,
          className: 'scoreboard-flag-cell'
        })
      }
    })

    this.setState({
      columns
    })
  }

  componentDidMount () {
    this._isMounted = true

    this.setColumns()
  }

  componentDidUpdate (prevProps, prevState) {
    const { ranking, language } = this.props
    if ((language !== prevProps.language) || (ranking.length !== prevProps.ranking.length)) {
      if (this._isMounted) {
        this.setColumns()
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { ranking } = this.props
    const { columns } = this.state

    return (
      <Table
        className={`ranking-table ${ranking.length ? 'fade-in' : ''}`}
        dataSource={ranking}
        columns={columns}
        pagination={false}
      />
    )
  }
}

export default StyledRanking

import React from 'react'
import Terminal from './Terminal'
import { getTicket } from '../../../actions/'
import { getCookie, openNotificationPopup } from '../../../actions/helpers'
import { messageTypes } from '../../../actions/messages'
import { Button } from 'antd'
import StyledLoader from '../../../styledComponents/common/loader'
import { handleFullscreen, handleCtrlAltDel } from './terminalHelpers.js'

export default class ConsoleEmulator extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      ticketData: null
    }
    this.consoleEmulatorRef = React.createRef()
  }

  getTicket (machineId) {
    const { language } = this.props
    if (machineId) {
      getTicket(machineId, getCookie('_token')).then((json) => {
        if (this._isMounted) {
          if (json.status === 'ok') {
            this.setState({ ticketData: json.response })
          } else if (json.status === 'err') {
            this.setState({ ticketData: null })
            openNotificationPopup(
              messageTypes[language].oops,
              messageTypes[language].machine_console_err,
              'frown',
              json.response[language] || json.response,
              language
            )
          }
        }
      })
    }
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentDidUpdate (prevProps, prevState) {
    const { machineId, machineFound } = this.props

    if (prevProps.machineFound === null && machineFound === true) {
      this.getTicket(machineId)
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { ticketData } = this.state
    const { language } = this.props

    return (
      <div className='console-window'>
        {ticketData
          ? (
            <>
              <Terminal
                consoleEmulatorRef={this.consoleEmulatorRef}
                ticketData={ticketData}
                fadeIn={ticketData}
                language={language}
              />
              <div className='console-buttons'>
                <Button key='ctrl' onClick={() => { handleCtrlAltDel(this.consoleEmulatorRef) }}>{messageTypes[language].ctrl_alt_del}</Button>
                <Button key='fullscreen' onClick={() => { handleFullscreen(this.consoleEmulatorRef) }}>{messageTypes[language].fullscreen}</Button>
              </div>
            </>
            )
          : ''}
        <StyledLoader fadeOut={ticketData} />
      </div>
    )
  }
}

import React from 'react'
import { Table, Divider, Input, Button } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import Highlighter from 'react-highlight-words'
import { messageTypes } from '../../../../actions/messages'

class StyledUsersTableSettings extends React.Component {
  state = {
    columns: []
  }

  settings = {
    bordered: false,
    // loading: false,
    pagination: { position: 'bottom' },
    size: 'default',
    // expandedRowRender,
    showHeader: true
    // rowSelection: {},
  }

  // ###########################################
  getColumnSearchProps = (dataIndex, language) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node }}
          placeholder={messageTypes[language]['table_search_' + dataIndex]}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon={<SearchOutlined />}
          size='small'
          style={{ width: 90, marginRight: 8 }}
        >
          {messageTypes[language].search}
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size='small'
          style={{ width: 90 }}
        >
          {messageTypes[language].clear}
        </Button>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined className={filtered ? 'active' : ''} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select())
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : ''}
      />
    )
  })

  handleSearch = (selectedKeys, confirm) => {
    confirm()
    this.setState({ searchText: selectedKeys[0] })
  }

  handleReset = (clearFilters) => {
    clearFilters()
    this.setState({ searchText: '' })
  }
  // ###########################################

  setColumns () {
    const { language } = this.props

    // columns to show for default
    const columns = [{
      title: messageTypes[language].name,
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name', language),
      sorter: (a, b) => { return a.name.localeCompare(b.name) }
    }, {
      title: messageTypes[language].login_email,
      dataIndex: 'email',
      key: 'email',
      sorter: (a, b) => { return a.email.localeCompare(b.email) },
      ...this.getColumnSearchProps('email', language)
    }, {
      title: messageTypes[language].default_stack,
      dataIndex: 'stack',
      key: 'stack',
      sorter: (a, b) => { return a.stack && b.stack && a.stack.name.localeCompare(b.stack.name) },
      render: (text, record) => (
        <span>
          {this.props.stack && record.stack && this.props.stack._id === record.stack._id
            ? (
              <span title={messageTypes[language].your_current_stack}>{record.stack && record.stack.name + ' (' + record.stack.type + ')'}</span>)
            : (
              <a href='#show-user' title={messageTypes[language].switch_to_this_stack} className='show-btn' onClick={() => { this.props.switchToStack(record.stack) }}>{record.stack && record.stack.name + ' (' + record.stack.type + ')'}</a>)}
        </span>
      )
    }, {
      title: messageTypes[language].role,
      dataIndex: 'role',
      key: 'role',
      sorter: (a, b) => { return a.role.localeCompare(b.role) },
      ...this.getColumnSearchProps('role', language)
    }, {
      title: messageTypes[language].actions,
      key: 'action',
      render: (text, record) => (
        <span>
          <a href='#show-user' className='show-btn' onClick={() => { this.props.setShowDataId(record._id) }}>{messageTypes[language].show}</a>
          <Divider type='vertical' />
          <a href='#edit-user' className='edit-btn' onClick={() => { this.props.setDataId(record._id) }}>{messageTypes[language].edit}</a>
          <Divider type='vertical' />
          <a href='#delete-user' className='delete-btn' onClick={() => { this.props.deleteRecord(record._id) }}>{messageTypes[language].delete}</a>
        </span>
      )
    }]

    this.setState({
      columns
    })
  }

  componentDidMount () {
    this._isMounted = true
    this.setColumns()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, language } = this.props
    if ((language !== prevProps.language) || (data.length !== prevProps.data.length)) {
      if (this._isMounted) {
        this.setColumns()
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { columns } = this.state
    const { data, language } = this.props

    return (
      <Table
        className={`settings-table ${data && data.length ? 'fade-in' : ''}`}
        {...this.settings}
        columns={columns}
        dataSource={data}
        locale={{
          triggerDesc: messageTypes[language].table_sort_desc,
          triggerAsc: messageTypes[language].table_sort_asc,
          cancelSort: messageTypes[language].table_sort_cancel
        }}
      />
    )
  }
}

export default StyledUsersTableSettings

import React, { Component } from 'react'

class LoaderImage extends Component {
  render () {
    return (
      <div className='loader-svg fade-in-2'>
        <svg
          id='loader-svg' className='loader fade-in-2' version='1.0' xmlns='http://www.w3.org/2000/svg'
          width='395.000000pt' height='392.000000pt' viewBox='0 0 395.000000 392.000000'
          preserveAspectRatio='xMidYMid meet'
        >
          <metadata>
            Created by potrace 1.14, written by Peter Selinger 2001-2017
          </metadata>
          <g
            transform='translate(0.000000,392.000000) scale(0.100000,-0.100000)'
            fill='#000000' stroke='none'
          >

            <path
              id='path-left' d='M82 3890 c-18 -11 -41 -34 -52 -52 -20 -33 -20 -51 -18 -1883 l3
          -1850 24 -32 c38 -51 75 -63 193 -63 116 0 152 14 188 72 20 32 20 53 20 1105
          0 1037 -1 1075 -19 1124 -18 46 -18 50 -3 43 82 -34 87 -35 590 -32 562 3 530
          -1 573 86 18 36 20 56 17 146 -3 116 -14 140 -78 184 l-34 23 -500 -3 c-491
          -3 -502 -3 -544 -25 -37 -18 -42 -19 -36 -5 33 78 33 87 33 587 1 473 0 502
          -18 529 -37 56 -65 66 -191 66 -97 0 -120 -3 -148 -20z'
            />

            <path
              id='path-right' d='M3590 3891 c-20 -11 -44 -36 -58 -62 l-23 -43 3 -491 c3 -480 3 -491
          25 -537 l22 -48 -42 23 c-42 22 -43 22 -549 22 -498 0 -507 0 -535 -21 -61
          -45 -73 -77 -73 -193 0 -122 14 -161 69 -198 l34 -23 501 0 c478 0 503 1 545
          20 24 11 44 20 46 20 1 0 -7 -19 -19 -43 -21 -42 -21 -45 -21 -932 0 -957 -3
          -891 43 -977 2 -4 -16 3 -40 15 -42 22 -51 22 -541 25 -563 3 -547 5 -592 -73
          -22 -37 -25 -53 -25 -144 0 -112 8 -137 60 -186 l31 -30 693 -3 692 -2 34 22
          c18 13 43 36 54 51 l21 28 2 1842 3 1842 -23 39 c-35 61 -73 76 -197 76 -83 0
          -112 -4 -140 -19z'
            />

            <path
              id='path-center' className='svg-center' d='M1857 2057 c-21 -6 -49 -23 -63 -36 l-24 -23 0 -682 0 -683 23 -20
          c41 -38 64 -43 187 -43 129 0 164 9 194 52 14 20 16 102 16 698 l0 677 -22 20
          c-13 11 -34 26 -48 33 -34 18 -215 23 -263 7z'
            />

          </g>
        </svg>
      </div>
    )
  }
}

export default LoaderImage

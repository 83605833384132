import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledStackDescriptionsTableSettings from '../../../../styledComponents/sections/settings/stackDescriptions/stackDescriptionsTable'
import AddRecordButton from '../../../../styledComponents/common/addRecordButton'
import StacksModalSettings from './stackDescriptionModal'
import {
  openNotificationPopup,
  getCookie,
  deleteElementWithIdFromArr
} from '../../../../actions/helpers'

import {
  stackDescriptionsGetBuilder,
  stackDescriptionsCreateBuilder,
  stackDescriptionsUpdateBuilder,
  stackDescriptionsDeleteBuilder
} from '../../../../actions/'

import { messageTypes } from '../../../../actions/messages'

class StackDescriptionsSettings extends Component {
  state = {
    data: [],
    dataId: null,
    loading: false,
    isNewRecord: false
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  setDataId = (dataId) => { this.setState({ dataId }) }
  showAddRecordModal = (isNewRecord) => { this.setState({ isNewRecord }) }

  getAllData = () => {
    const { language } = this.props
    stackDescriptionsGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            data: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  deleteRecord = (id) => {
    const { language } = this.props
    stackDescriptionsDeleteBuilder(id, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({ data: deleteElementWithIdFromArr(id, this.state.data) })
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[this.props.language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  updateRecord = (id, data) => {
    const { language } = this.props
    this.setState({ loading: true })
    stackDescriptionsUpdateBuilder(id, data, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        this.setState({
          loading: false
        })
        if (json.status === 'ok') {
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
          this.getAllData()
          this.setState({
            dataId: null
          })
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  addRecord = (data) => {
    const { language } = this.props
    this.setState({ loading: true })
    stackDescriptionsCreateBuilder(data, getCookie('_token')).then((json) => {
      this.setState({ loading: false })
      if (json.status === 'ok') {
        openNotificationPopup(messageTypes[language].success, json.response[language], 'smile')
        this.setState({ isNewRecord: false })
        this.getAllData()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
      }
    })
  }

  returnWithKey (data, lang) {
    const retArr = []
    data.forEach((val, i) => {
      const obj = {}
      obj.key = i
      obj._id = val._id
      obj.stack_type = val.stack_type
      retArr.push(obj)
    })
    return retArr
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { data, dataId, isNewRecord, machines } = this.state
    const { language } = this.props
    return (
      <div>
        <AddRecordButton
          language={language}
          showAddRecordModal={this.showAddRecordModal}
          title={messageTypes[language].new_stack_description}
        />
        <StyledStackDescriptionsTableSettings
          language={language}
          data={this.returnWithKey(data, language)}
          setDataId={this.setDataId}
          deleteRecord={this.deleteRecord}
          showAddRecordModal={this.showAddRecordModal}
        />
        <StacksModalSettings
          language={language}
          machines={machines}
          data={data}
          dataId={dataId}
          isNewRecord={isNewRecord}
          setDataId={this.setDataId}
          loading={this.state.loading}
          updateRecord={this.updateRecord}
          showAddRecordModal={this.showAddRecordModal}
          addRecord={this.addRecord}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language
})

const StackDescriptionsSettingsContainer = connect(
  mapStateToProps
)(StackDescriptionsSettings)

export default StackDescriptionsSettingsContainer

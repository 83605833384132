import React from 'react'
import { notification, Button, Popover } from 'antd'
import {
  HomeOutlined,
  FileProtectOutlined,
  ClusterOutlined,
  BarChartOutlined,
  ProfileOutlined,
  FileTextOutlined,
  AntCloudOutlined,
  EyeOutlined,
  UserOutlined,
  QuestionCircleOutlined,
  FrownOutlined,
  SmileOutlined
} from '@ant-design/icons'
import Swal from 'sweetalert2'
import { messageTypes } from './messages'
import settings from '../config/'

export function isNum (number) {
  return number !== null && !isNaN(number)
}

export function returnMachinesInJson (data, language) {
  const retObj = {}
  data.forEach((v, i) => {
    retObj[v.id] = v.name.pl
  })
  // console.log(retObj);
  return retObj
}

/*
 * Prepare machines name in format: {machine_name_lowered: machine_name, ...}
 */
export function returnMachinesInArr (data) {
  const retArr = {}

  data.forEach((v, i) => {
    retArr[v.toLowerCase()] = v
  })

  return retArr
}

export function toHMStime (timeSec) {
  const secNum = parseInt(timeSec, 10)
  let hours = Math.floor(secNum / 3600)
  let minutes = Math.floor((secNum - (hours * 3600)) / 60)
  let seconds = secNum - (hours * 3600) - (minutes * 60)

  if (hours < 10) { hours = '0' + hours }
  if (minutes < 10) { minutes = '0' + minutes }
  if (seconds < 10) { seconds = '0' + seconds }
  return hours + ':' + minutes + ':' + seconds
}

export function numberToArray (num) {
  const rows = []
  for (let i = 0; i < num; i++) {
    rows.push(i)
  }
  return rows
}

export function takeElementWithIdFromArr (id, arr) {
  if (!(id && arr && arr.length)) {
    return null
  }
  let el = null
  arr.forEach((val, i) => {
    if (id === val._id) {
      el = val
    }
  })
  return el
}

export function deleteElementWithIdFromArr (id, arr) {
  if (!(id && arr && arr.length)) {
    return null
  }
  const retArr = []
  arr.forEach((val, i) => {
    if (id !== val._id) {
      retArr.push(val)
    }
  })
  return retArr
}

export function returnWithKey (arr) {
  return arr.map((val, i) => {
    const ret = val
    ret.key = i
    return ret
  })
}

export function renderIcon (icon) {
  if (icon === 'home') return <HomeOutlined />
  if (icon === 'file-protect') return <FileProtectOutlined />
  if (icon === 'cluster') return <ClusterOutlined />
  if (icon === 'bar-chart') return <BarChartOutlined />
  if (icon === 'question-circle') return <QuestionCircleOutlined />
  if (icon === 'user') return <UserOutlined />
  if (icon === 'eye') return <EyeOutlined />
  if (icon === 'ant-cloud') return <AntCloudOutlined />
  if (icon === 'file-text') return <FileTextOutlined />
  if (icon === 'profile') return <ProfileOutlined />
  if (icon === 'frown') return <FrownOutlined />
  if (icon === 'smile') return <SmileOutlined />
  return ''
}

export function openNotificationPopup (title, message, icon, response, language, duration = 2.5) {
  const content = response
    ? (
      <div>
        <p>{response}</p>
      </div>
      )
    : null

  const popover = (response && language)
    ? (
      <Popover content={content} title={messageTypes[language].server_response}>
        <Button type='primary'>{messageTypes[language].show_server_response}</Button>
      </Popover>
      )
    : null

  return notification.open({
    message: title,
    description: message,
    icon: renderIcon(icon),
    duration,
    btn: popover
  })
}

export function confirm (type, language, fn, fnExit = null, typeCheckbox = null) {
  if (type === null) {
    return fn()
  }
  const title = messageTypes[language].question
  const cancel = messageTypes[language].cancel
  const confirm = messageTypes[language].confirm
  const text = messageTypes[language][type]
  let elm1
  let elm2

  if (typeCheckbox) {
    const textCheckbox = messageTypes[language][typeCheckbox]

    elm1 = document.createElement('div')
    elm1.appendChild(document.createTextNode(text))

    elm2 = document.createElement('span')
    elm2.appendChild(document.createTextNode(textCheckbox))
  }

  Swal.fire({
    title,
    text: !typeCheckbox && text,
    html: typeCheckbox && (elm1.innerHTML + '<div class="confirm_checkbox"><input id="confirm_checkbox" type="checkbox" ' + (settings.stack_switch_default_change_state ? 'checked ' : '') + 'value="1"> <label for="confirm_checkbox">' + elm2.innerHTML + '</label></div>'),
    showCancelButton: true,
    confirmButtonText: confirm,
    cancelButtonText: cancel
  }).then((result) => {
    if (result.value) {
      return fn()
      // Swal.fire(
      //   'Done!',
      //   'success'
      // )
    } else {
      return fnExit !== null ? fnExit() : 0
    }
  })
}

export function arraysEqual (arr1, arr2) {
  if (arr1.length !== arr2.length) { return false }
  for (let i = arr1.length; i--;) {
    if (arr1[i] !== arr2[i]) { return false }
  }
  return true
}

export function regexEscape (string) {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}

export function namesMatch (searchString, description) {
  let match = false
  const re = new RegExp(regexEscape(searchString), 'gi')
  if (description && description.match(re)) {
    match = true
  }
  return match
}

export function tagsMatchOr (matchString, tagsArr) {
  let matchOr = false
  if (matchString && tagsArr && tagsArr.length) {
    const matchArr = matchString.split(' ')
    matchArr.forEach((valA, i) => {
      if (valA) {
        const re = new RegExp(regexEscape(valA), 'gi')
        tagsArr.forEach((valB, i) => {
          if (valB.match(re)) {
            matchOr = true
          }
        })
      }
    })
  }
  return matchOr
}

export function setCookie (cname, cvalue, time) {
  const d = new Date()
  d.setTime(time)
  const expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
}

export function modifyCookie (cname, cvalue) {
  document.cookie = cname + '=' + cvalue + ';path=/'
}

export function getCookie (cname) {
  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }
}

export function deleteCookie (cname) {
  setCookie(cname, '', '')
}

export function slugify (str) {
  return str
    .trim()
    .toLowerCase()
    .replace(/\s+/g, '-')
}

// export function tagsMatchAnd(matchString, tagsArr) {
//
//   let matchAnd = true;
//   if(matchString && tagsArr && tagsArr.length) {
//     const matchArr = matchString.split(" ");
//     matchArr.forEach((valA, i) => {
//       let matchSingleAnd = false;
//       let re = new RegExp( regexEscape(valA), "gi" );
//       tagsArr.forEach((valB, i) => {
//         console.log(valA, valB)
//         if(valB.match(re)) {
//           matchSingleAnd = true;
//         }
//       });
//       if(!matchSingleAnd) {
//         matchAnd = false;
//       }
//     });
//   }
//   return matchAnd;
// }

import axios from 'axios'
import { openNotificationPopup } from './helpers'
import settings from '../config/'
import { messageTypes } from './messages'

axios.defaults.validateStatus = function (status) {
  return status >= 200 && status < 500
}

export function copyToClipboard (str, language) {
  const el = document.createElement('textarea')
  el.value = str
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)
  // show notify
  openNotificationPopup(messageTypes[language].copied_to_clipboard, str, 'smile')
};

// USER SETTINGS
export function singleUserUpdate (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/users/update`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}
//
// START SETTINGS
//
// QUIZZES
//
export function quizzesGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/quiz`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizzesForUser (userId, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/quiz/for-user/${userId}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizForUser (quizId, userId, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/quiz/for-user/${userId}/${quizId}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizzesCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/quiz`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizzesUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/quiz/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizzesDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/quiz/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}
//
// END QUIZZES
//
// SCENARIOS
//
export function scenariosGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/scenarios`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// download scenario file
export function scenarioFileDownload (id, file, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/scenarios/download-file/${id}/${file}`
    }).then(function (response) {
      resolve(response)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioBlock (id, days, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/scenarios/${id}`,
      data: { days }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioUnblock (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/scenarios/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioExtendBlock (id, extendDays, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/scenarios/${id}/extend`,
      data: { extend_days: extendDays }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenariosUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/scenarios/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenariosCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/scenarios`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenariosDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/scenarios/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}
// Vpn
export function vpnsGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpns`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function vpnsCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpns`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function vpnsUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpns/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function vpnsDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpns/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// Vpn descriptions
export function vpnDescriptionsGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpn_descriptions`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function vpnDescriptionsCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpn_descriptions`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function vpnDescriptionsUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpn_descriptions/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function vpnDescriptionsDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/vpn_descriptions/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// Stack descriptions
export function stackDescriptionsGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stack_descriptions`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackDescriptionsCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stack_descriptions`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackDescriptionsUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stack_descriptions/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackDescriptionsDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stack_descriptions/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// get map
export function mapGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/map`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// submit flag
export function mapSubmitFlag (flag, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/map/submit_flag`,
      data: { flag },
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// get current stack map objects
export function userStackMapObjectsGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/stack/map/objects`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// download map object file
export function mapObjectFileDownload (id, token, file) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/stack/map/objects/download-file/${id}/${file}`
    }).then(function (response) {
      resolve(response)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function mapObjectsGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/map_objects`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function mapObjectsCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/map_objects`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function mapObjectsUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/map_objects/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function mapObjectsDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/map_objects/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}
//
// END SCNEARIOS
//
// MACHINES
//
export function machinesGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/machines`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// download machine file
export function machineFileDownload (machineName, token, file) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/machines/download-file/${machineName}/${file}`
    }).then(function (response) {
      resolve(response)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machinesGetVmware (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/admin/machines_vmware`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machinesUpdateBuilder (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/machines/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machinesCreateBuilder (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/machines`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machinesDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/machines/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}
//
// END MACHINES
//
export function usersGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/users`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function userCreate (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      url: `${settings.api_url}/admin/users`,
      headers: {
        Authorization: 'Bearer ' + token
      },
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function userDelete (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/users/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function userUpdate (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/users/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export async function sessionsGet (token) {
  try {
    const response = await axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/sessions`
    })
    return response.data
  } catch (error) {
    openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
  }
}
// END SETTINGS

export function rankingGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/ranking`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizzesGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/quiz`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizQuestionsGet (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/quiz/${id}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function quizAnswerPost (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/quiz/${id}`,
      data,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenariosGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/scenarios`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function switchStack (targetStackId, changeStackState, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/stack/${targetStackId}/switch/${changeStackState}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// export function switchSubstack (targetStackType, token) {
//   return new Promise(function (resolve, reject) {
//     axios({
//       method: 'POST',
//       // url: `${settings.api_url}/stack/${targetStackType}/switch`,
//       url: `${settings.api_url}/stack/${targetStackType}/switch-substack`,
//       headers: {
//         Authorization: 'Bearer ' + token
//       }
//     }).then(function (response) {
//       resolve(response.data)
//     }).catch(function (error) {
//       openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
//     })
//   })
// }

export function stacksGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stacks`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stacksOverlordGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/overlord/stacks`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackCreate (data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stacks/`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackUpdate (id, data, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stacks/${id}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackDelete (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/stacks/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioRevert (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/scenarios/${id}/revert`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machinesStackAction (token, action) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/machines/stack/${action}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function stackAction (id, action, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/stack/${id}/${action}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioTurnOn (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/scenarios/${id}/on`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioTurnOff (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/scenarios/${id}/off`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioTest (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/scenarios/${id}/test`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function scenarioLogGenerate (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/scenarios/${id}/log`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}
//
/// / MACHINES
//
export function machinesGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/machines`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machineGet (vmwareId, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/machines/${vmwareId}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve({ data: response.data, http_status: response.status })
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export async function machineReboot (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/machines/${id}/reboot`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machineRevert (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/machines/${id}/revert`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machineTurnOn (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/machines/${id}/on`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function machineTurnOff (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/machines/${id}/off`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function getTicket (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/machines/${id}/ticket`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function pingConsoleSession (sessId, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/machines/ping_console_session/${sessId}`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function logsGetBuilder (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      url: `${settings.api_url}/admin/logs`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function logDeleteBuilder (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/admin/logs/${id}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function signin (email, password) {
  const authData = Buffer.from(email + ':' + password, 'binary').toString('base64')
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/signin`,
      headers: {
        Authorization: 'Basic ' + authData
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function signup (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      url: `${settings.api_url}/signup`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function resetPassword (data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/reset-password`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function resetPasswordCheckToken (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/reset-password/${token}`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function resetPasswordSetPassword (token, data) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'PUT',
      url: `${settings.api_url}/reset-password/${token}`,
      data
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function signout (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'POST',
      url: `${settings.api_url}/signout`,
      headers: {
        Authorization: 'Bearer ' + token
      }
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// download ca certificate
export function certificateCaDownload (type, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      responseType: 'arraybuffer',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/users/download-ca-certificate/${type}`
    }).then(function (response) {
      resolve(response)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

// Stack
export function userStackGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/stack`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function userStackVpnsGet (token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/stack/vpns`
    }).then(function (response) {
      resolve(response.data)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

export function userStackVpnDownload (id, token) {
  return new Promise(function (resolve, reject) {
    axios({
      method: 'GET',
      headers: {
        Authorization: 'Bearer ' + token
      },
      url: `${settings.api_url}/stack/vpns/download/${id}`
    }).then(function (response) {
      resolve(response)
    }).catch(function (error) {
      openNotificationPopup(messageTypes.universal.error, error.message, 'frown')
    })
  })
}

import React, { Component } from 'react'
import { Menu, Dropdown, Button } from 'antd'
import { AntCloudOutlined, DownOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'
import { userStackVpnDownload } from '../../actions'
import { openNotificationPopup, getCookie, slugify } from '../../actions/helpers'
import { setStackVpns } from '../../store/actions'
import { connect } from 'react-redux'
const FileDownload = require('js-file-download')

class VpnDropdown extends Component {
  downloadVpn (vpn, stack) {
    userStackVpnDownload(vpn._id, getCookie('_token')).then((response) => {
      const filename = vpn && stack && vpn.network_name ? 'hd-' + vpn.network_name + '-' + slugify(stack.name) + '.ovpn' : 'vpn-hackingdept.ovpn'

      if (response.status === 200) {
        // download file
        FileDownload(response.data, filename)
      } else {
        openNotificationPopup(messageTypes[this.props.language].oops, messageTypes[this.props.language].vpn_download_err, 'frown')
      }
    })
  }

  render () {
    const { stackVpns, stack } = this.props

    const menu = (
      <Menu>
        {
          stackVpns && stackVpns.length
            ? stackVpns.map((element, n) =>
              <Menu.Item key={n} onClick={() => this.downloadVpn(element, stack)}>
                <span>{element.name[this.props.language]}</span>
              </Menu.Item>
              )
            : ''
        }
      </Menu>
    )

    return (
      stack !== 'switching' && stackVpns && stackVpns.length
        ? (
          <Dropdown overlay={menu}>
            <Button>
              <AntCloudOutlined /> {messageTypes[this.props.language].vpns} <DownOutlined />
            </Button>
          </Dropdown>
          )
        : false
    )
  }
}

const mapStateToProps = state => ({
  stackVpns: state.hdStore.stackVpns,
  stack: state.hdStore.stack
})

const mapDispatchToProps = {
  setStackVpns
}

const VpnDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(VpnDropdown)

export default VpnDropdownContainer

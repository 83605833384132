import React, { Component } from 'react'
import { Switch, Tooltip } from 'antd'
import { messageTypes } from '../../actions/messages.js'

class StyledPowerSwitch extends Component {
  render () {
    const { status, language, power } = this.props
    const powerDisabled = power === false || (this.props.status !== 'poweredOn' && this.props.status !== 'poweredOff' && this.props.status !== 'suspended')

    return (
      <Tooltip placement='top' title={powerDisabled ? messageTypes[this.props.language].power_is_disabled : ''}>
        <Switch
          checkedChildren={messageTypes[language].status_poweredOn}
          unCheckedChildren={messageTypes[language].status_poweredOff}
          checked={status === 'poweredOn'}
          disabled={powerDisabled}
          onChange={() => this.props.handleSwitch(this.props.id)}
        />
      </Tooltip>
    )
  }
}

export default StyledPowerSwitch

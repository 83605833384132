import React from 'react'
import HDComponent from '../../../components/common/hdComponent'
import { Row, Col } from 'antd'
import ResetPasswordSetPasswordForm from './setPasswordForm'

class StyledResetPasswordRequestForm extends HDComponent {
  render () {
    const { language, token } = this.props

    return (
      <Row>
        <Col lg={{ span: 8 }} md={{ span: 0 }} />
        <Col lg={{ span: 8 }} md={{ span: 24 }}>
          <div className='reset-password-logo'>
            <img src='/images/logo_light_small.png' alt='HD Logo' />
          </div>
          <ResetPasswordSetPasswordForm
            language={this.determineLanguage(language)}
            token={token}
          />
        </Col>
        <Col lg={{ span: 8 }} md={{ span: 0 }} />
      </Row>
    )
  }
}

export default StyledResetPasswordRequestForm

import React, { Component } from 'react'
import { connect } from 'react-redux'
import StyledLogsTableSettings from '../../../../styledComponents/sections/settings/logging/logsTable'
import FilterButtons from './filterButtons'
import LogsModal from './logsModal'
import {
  getCookie,
  deleteElementWithIdFromArr,
  openNotificationPopup,
  namesMatch
} from '../../../../actions/helpers'
import { messageTypes } from '../../../../actions/messages'
import { logsGetBuilder, logDeleteBuilder } from '../../../../actions/'
import dateformat from 'dateformat'

class MachinesSettings extends Component {
  state = {
    modalVisible: false,
    verbosity: 1,
    singleLog: null,
    logs: [],
    matchLogs: []
  }

  componentDidMount () {
    this._isMounted = true
    this.getAllData()
  }

  getAllData = () => {
    const { language } = this.props
    logsGetBuilder(getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({
            logs: json.response,
            matchLogs: json.response
          })
        } else if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            json.response[language],
            'frown'
          )
        }
      }
    })
  }

  toggleLogModal = (modalVisible, singleLog) => {
    this.setState({ modalVisible, singleLog })
  }

  deleteRecord = (id) => {
    const { language } = this.props
    logDeleteBuilder(id, getCookie('_token')).then((json) => {
      if (this._isMounted) {
        if (json.status === 'ok') {
          this.setState({ logs: deleteElementWithIdFromArr(id, this.state.logs) })
          openNotificationPopup(
            messageTypes[language].success,
            json.response[language],
            'smile'
          )
        } else if (json.status === 'err') {
          openNotificationPopup(messageTypes[language].oops, json.response[language], 'frown')
        }
      }
    })
  }

  resetFilters = () => {

  }

  changeVerbosity = (e) => {
    this.setState({ verbosity: e.target.value })
  }

  returnWithKey (data) {
    const { verbosity } = this.state
    const resp = data && data.reduce(function (filtered, val) {
      if (val.verbosity <= verbosity) {
        try {
          val.short_description = val.description.split(':')[0].substr(0, 30)
        } catch (e) {
          val.short_description = ''
        }
        val.user = (val.user_id && val.user_id.name) || ''
        val.key = val._id
        try {
          val.created_at = val.created_at !== undefined ? dateformat(val.created_at, 'yyyy-mm-dd HH:MM:ss') : ''
        } catch (e) {
          val.created_at = ''
        }

        filtered.push(val)
      }
      return filtered
    }, [])

    return resp
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.searchPassphrase !== prevProps.searchPassphrase) {
      if (!this.props.searchPassphrase) {
        this.setState({ matchLogs: this.state.logs })
      } else {
        const matchLogs = []
        const pattern = (this.props.searchPassphrase || '')
        this.state.logs.forEach((val, i) => {
          if (val.description && namesMatch(pattern, val.description)) {
            matchLogs.push(val)
          }
        })
        if (this._isMounted) {
          this.setState({ matchLogs })
        }
      }
    }
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  render () {
    const { logs, matchLogs, modalVisible, singleLog, verbosity } = this.state
    const { language } = this.props
    return (
      <div className={`settings-table ${logs && logs.length ? 'fade-in' : ''}`}>
        <StyledLogsTableSettings
          language={language}
          logs={this.returnWithKey(matchLogs)}
          deleteRecord={this.deleteRecord}
          toggleLogModal={this.toggleLogModal}
        />
        <FilterButtons
          language={language}
          verbosity={verbosity}
          handleVerbosity={this.changeVerbosity}
          handleFilters={this.resetFilters}
        />
        {singleLog !== null
          ? (
            <LogsModal
              language={language}
              singleLog={singleLog}
              modalVisible={modalVisible}
              toggleLogModal={this.toggleLogModal}
            />
            )
          : ''}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  searchPassphrase: state.hdStore.searchPassphrase
})

const MachinesSettingsContainer = connect(
  mapStateToProps
)(MachinesSettings)

export default MachinesSettingsContainer

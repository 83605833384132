import React, { Component } from 'react'
import { pingConsoleSession } from '../../../actions/'
import { messageTypes } from '../../../actions/messages'
import { getCookie, openNotificationPopup } from '../../../actions/helpers'

class Terminal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sessionPingJob: null
    }
  }

  handleIframeLoad (consoleEmulatorRef) {
    consoleEmulatorRef.current.contentWindow.document.getElementById('mainCanvas').focus()
  }

  pingConsoleSession = (sessId) => {
    const { language } = this.props

    pingConsoleSession(sessId, getCookie('_token')).then((json) => {
      const _this = this

      if (this._isMounted) {
        const sessionPingJob = setTimeout(function () { _this.pingConsoleSession(sessId) }, 10000)
        this.setState({ sessionPingJob })
        if (json.status === 'err') {
          openNotificationPopup(
            messageTypes[language].oops,
            messageTypes[language].machine_console_err,
            'frown',
            json.response[language] || json.response,
            language
          )
        }
      }
    })
  }

  componentDidMount () {
    this._isMounted = true
  }

  componentWillUnmount () {
    const { sessionPingJob } = this.state
    this._isMounted = false

    if (sessionPingJob) {
      clearTimeout(sessionPingJob)
    }
  }

  render () {
    const { ticketData, consoleEmulatorRef } = this.props
    const consoleUri = `/web_console/index.html?type=${ticketData.type}&ticket=${ticketData.ticket}&host=${ticketData.host}&port=${ticketData.port}&cfgFile=${encodeURIComponent(ticketData.cfgFile)}&thumbprint=${ticketData.thumbprint}&sessionId=${ticketData.sessionId}&vmId=${ticketData.vmId}&version=${ticketData.version}&serverGuid=${ticketData.serverGuid}`

    if (!this.state.sessionPingJob && ticketData && ticketData.sessionId) {
      this.pingConsoleSession(ticketData.sessionId)
    }

    return (
      <iframe
        title='ESXI console'
        ref={consoleEmulatorRef}
        id='console-emulator'
        width='100%'
        height='100%'
        src={consoleUri}
        onLoad={() => this.handleIframeLoad(consoleEmulatorRef)}
        sandbox='allow-same-origin allow-scripts'
      />
    )
  }
}

export default Terminal

import React from 'react'
import config from '../../config/'
import { messageTypes } from '../../actions/messages'
import { switchStack } from '../../actions'
import { openNotificationPopup, getCookie, confirm } from '../../actions/helpers'

class StackSwitchComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      switching: false,
      switching_to: null
    }
  }

  doSwitch = (targetStack) => {
    const { /* setCurrentStackMachines, */ setStack, stack } = this.props

    const changeStackState = !!(document.getElementById('confirm_checkbox') && document.getElementById('confirm_checkbox').checked)

    setStack('switching')
    this.setState({ switching: true, switching_to: targetStack })

    switchStack(targetStack._id, changeStackState, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        // setCurrentStackMachines(stack.machines_visible)
        setStack(json.stack)

        openNotificationPopup(messageTypes[this.props.language].success, json.response && json.response[this.props.language], 'smile')
      } else if (json.status === 'restore') {
        // restore status means that stack wasn't ready to switch into it
        setStack(stack)
        openNotificationPopup(messageTypes[this.props.language].oops, json.response && json.response[this.props.language], 'frown')
      } else {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response && json.response[this.props.language], 'frown')
      }

      this.setState({ switching: false, switching_to: null })
    })
  }

  switchStack = (stack) => {
    if (config.stack_switch_state === 'poweroff' || config.stack_switch_state === 'suspend') {
      confirm('stack_switch_question', this.props.language, () => { this.doSwitch(stack) }, null, 'stack_switch_' + config.stack_switch_state)
    } else {
      this.doSwitch(stack)
    }
  }
}

export default StackSwitchComponent

import React, { Component } from 'react'

class NoMatch extends Component {
  render () {
    return (
      <h1 className='text-404'>Strona nie istnieje</h1>
    )
  }
}

export default NoMatch

import React, { Component } from 'react'
// import ResourcesVisualiser from '../../common/resourcesVisualiser';
import TagsDisplayer from './tagsDisplayer'
import { messageTypes } from '../../../actions/messages'

class StyledCardBody extends Component {
  render () {
    const { handleClick, element, language } = this.props
    const { tags, desc, desc_long: descLong } = element // resources
    const brDesc = desc[language].split('\n')

    return (
      <div className={descLong ? 'hoverable hoverable-scenarios' : ''} onClick={() => { descLong && handleClick(element) }}>
        <h4 style={{ textAlign: 'left' }}>
          {element.blocked_by && <p className='item-blocked'>{messageTypes[language].reserved_to} {element.blocked_to} {messageTypes[language].reserved_by} {element.blocked_by?.name}</p>}
          {
            brDesc.map((val, i) => (
              <div key={i}>{val} <br /></div>
            ))
          }
        </h4>
        {/* <ResourcesVisualiser resources={resources} /> */}
        <TagsDisplayer tags={tags} />
      </div>
    )
  }
}

export default StyledCardBody

import React, { Component } from 'react'

class StyledCardBody extends Component {
  render () {
    const { handleClick, element, language } = this.props
    const { desc } = element
    const langDesc = desc && desc[language]
    const brDesc = langDesc && langDesc.split('\n')

    return (
      <div className='hoverable' onClick={() => { handleClick(element._id) }}>
        <h4 style={{ textAlign: 'left' }}>
          {
            brDesc.map((val, i) => (
              <div key={i}>{val} <br /></div>
            ))
          }
        </h4>
      </div>
    )
  }
}

export default StyledCardBody

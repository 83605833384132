import React from 'react'
import FontAwesomeIcon from 'react-fontawesome'
import AnswerSendForm from './answerSendForm'
import Moment from 'react-moment'
import { isNum } from '../../../actions/helpers'
import { Modal, Button, Table } from 'antd'
import { messageTypes } from '../../../actions/messages'

class QuestionsListCard extends React.Component {
  constructor (props) {
    super(props)
    const { language } = this.props

    this.state = {
      quizNumber: null,
      dataSource: []
    }

    this.columns = [{
      title: messageTypes[language]._question,
      dataIndex: 'name',
      key: 'name'
    }, {
      title: messageTypes[language].solved,
      dataIndex: 'solved',
      key: 'solved'
    }]
  }

  componentDidMount () {
    this.setQuestions()
  }

  setQuestions = () => {
    const { language, quizList } = this.props
    const dataSource = (quizList && quizList.data && quizList.data.length)
      ? quizList.data.map((val, i) => {
          return {
            key: i,
            name: val.question.name[language],
            solved: val.solved ? <FontAwesomeIcon className='success' name='check' /> : <FontAwesomeIcon className='danger' name='close' />,
            incorrect_attempts: val.incorrect_attempts || 0
          }
        })
      : []

    this.setState({ dataSource })
  }

  setUnsolved = (id) => {
    let { dataSource } = this.state
    dataSource = dataSource.map((val, i) => {
      if (i === id) {
        val.incorrect_attempts += 1
      }
      return val
    })
    this.setState({ dataSource })
  }

  setSolved = (id) => {
    let { dataSource } = this.state
    dataSource = dataSource.map((val, i) => {
      if (i === id) {
        val.solved = <FontAwesomeIcon className='success' name='check' />
      }
      return val
    })
    this.setState({ dataSource })
  }

  setQuizNumber = (quizNumber) => {
    this.setState({ quizNumber })
  }

  render () {
    const { quizNumber } = this.state
    const {
      quizList,
      handleListQuit,
      language,
      quizId
    } = this.props
    const { dataSource } = this.state

    const quizName = (quizList &&
      quizList.scenario &&
      quizList.scenario.name &&
      quizList.scenario.name[language]) || ''

    const quizDateStarted = (quizList && quizList.started_at) || ''
    const quizDateSolved = (quizList && quizList.solved_at) || ''
    return (
      <Modal
        title={
          <div>
            {
              (false && quizDateStarted && !quizDateSolved) &&
                <div className='header-date danger'>
                  <Moment
                    durationFromNow
                    interval={1000}
                  >{quizDateStarted}
                  </Moment>
                </div>
            }
            {
              (false && quizDateStarted && quizDateSolved) &&
                <div className='header-date'>
                  <Moment
                    duration={quizDateStarted}
                    date={quizDateSolved}
                  />
                </div>
            }
            <div className='header-name'>{quizName}</div>
          </div>
        }
        visible={quizList && true}
        onCancel={handleListQuit}
        footer={[
          isNum(quizNumber) ? <Button key='back' onClick={() => { this.setQuizNumber(null) }}>{messageTypes[language].back}</Button> : '',
          <Button key='exit' onClick={handleListQuit}>{messageTypes[language].close}</Button>
        ]}
      >
        <div className={quizNumber ? 'modal-table-quiz' : 'modal-table-list'}>
          <div className={`modal-table ${!isNum(quizNumber) ? 'fade-in-2' : 'd-none'}`}>
            <Table
              onRow={(record, rowIndex) => {
                return {
                  onClick: (event) => { this.setQuizNumber(rowIndex) }
                }
              }}
              dataSource={dataSource}
              columns={this.columns}
              pagination={false}
            />
          </div>

          <div className={`modal-desc ${isNum(quizNumber) ? 'fade-in-2' : 'd-none'}`}>

            <p>
              {
                (isNum(quizNumber) &&
                  quizList &&
                  quizList.data &&
                  quizList.data[quizNumber] &&
                  quizList.data[quizNumber].question &&
                  quizList.data[quizNumber].question.desc &&
                  quizList.data[quizNumber].question.desc[language]
                )
                  ? (
                      quizList.data[quizNumber].question.desc[language].split('\\n').map((val, i) => (
                        <span key={i}>
                          {val} <br />
                        </span>
                      )
                      )
                    )
                  : ''
              }
            </p>
            <AnswerSendForm
              language={language}
              quizId={quizId}
              quizNumber={quizNumber}
              setQuizNumber={this.setQuizNumber}
              setQuestions={this.setQuestions}
              setSolved={this.setSolved}
              setUnsolved={this.setUnsolved}
              handleListQuit={handleListQuit}
            />
          </div>
          {(dataSource[quizNumber] && dataSource[quizNumber].incorrect_attempts) ? <div className='incorrect-attempts'>{`${messageTypes[language].incorrect_attempts}: ${dataSource[quizNumber].incorrect_attempts}`}</div> : ''}
        </div>
      </Modal>
    )
  }
}

export default QuestionsListCard

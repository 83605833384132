import React from 'react'
import { messageTypes } from '../../../../actions/messages'
import {
  Form, Select, Input, Button, Divider, Checkbox, Tag, InputNumber, Switch, Tooltip
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import settings from '../../../../config/'

const { Option } = Select

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

class StyledStackForm extends React.Component {
  formRef = React.createRef()
  state = {
    useExistingStack: true,
    useOverlord: settings.overlord_enabled
  }

  handleSubmit = (values) => {
    const { data, isNewRecord, addRecord, updateRecord } = this.props
    const obj = {}
    obj.use_overlord = this.state.useOverlord
    obj.name = values.name
    obj.demo = values.demo

    if (!this.state.useOverlord) {
      obj.type = values.type
      obj.nr = values.nr
      obj.is_ctf = values.is_ctf
      obj.vmware_user = values.vmware_user
      obj.vmware_password = values.vmware_password
      obj.vmware_admin_user = values.vmware_admin_user
      obj.vmware_admin_password = values.vmware_admin_password
    }

    if (isNewRecord && settings.overlord_enabled && this.state.useOverlord) {
      obj.create_new_stack = values.create_new_stack
      obj.type = values.type

      if (!values.create_new_stack) {
        obj.stack = values.stack
      }
    }

    if (isNewRecord) { addRecord(obj) } else { updateRecord(data._id, obj) }
  }

  setDefaultValues = () => {
    const { data, isNewRecord } = this.props

    if (data && Object.keys(data).length > 0 && !isNewRecord) {
      this.setState({}, () => {
        const fieldsVal = {
          name: data.name,
          demo: data.demo,
          type: data.type,
          is_ctf: data.is_ctf,
          nr: data.nr,
          vmware_user: data.vmware_user,
          vmware_admin_user: data.vmware_admin_user
        }
        this.formRef.current.setFieldsValue(fieldsVal)
      })
    } else {
      this.setState({
        useExistingStack: true,
        useOverlord: settings.overlord_enabled
      }, () => {
        this.formRef.current.setFieldsValue({
          name: undefined,
          demo: undefined,
          nr: undefined,
          is_ctf: false,
          stack: undefined,
          vmware_user: '',
          vmware_password: '',
          vmware_admin_user: '',
          vmware_admin_password: '',
          create_new_stack: false,
          type: ''
        })
      })
    }
  }

  componentDidMount () {
    this.setDefaultValues()
  }

  componentDidUpdate (prevProps, prevState) {
    const { data, isNewRecord } = this.props

    if (data._id !== prevProps.data._id || isNewRecord !== prevProps.isNewRecord) {
      this.setDefaultValues()
    }
  }

  handleStackChange = (value) => {
    this.setState({ useExistingStack: !value.target.checked })
  }

  handleSwitch = () => {
    this.setState({ useOverlord: !this.state.useOverlord })
  }

  handleNameChange = (value) => {
    this.props.setDataTitle(value.target.value)
  }

  render () {
    const { language, loading, stacks, isNewRecord, data } = this.props
    const { useExistingStack, useOverlord } = this.state

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal' ref={this.formRef}>

        <Form.Item
          name='use_overlord'
          label={messageTypes[language].use_overlord}
          {...formItemLayout}
        >
          <Tooltip placement='top' title={!settings.overlord_enabled && messageTypes[language].overlord_connection_unavailable}>
            <Switch
              checked={this.state.useOverlord}
              disabled={!settings.overlord_enabled}
              onChange={() => this.handleSwitch()}
            />
          </Tooltip>
        </Form.Item>

        <Form.Item
          name='name'
          label={messageTypes[language].stack_name}
          rules={[{ required: true, message: messageTypes[language].field_required }]}
          {...formItemLayout}
        >
          <Input onChange={this.handleNameChange} />
        </Form.Item>

        {isNewRecord && useOverlord && (
          <>
            <Form.Item
              name='create_new_stack'
              label={messageTypes[language].create_new_stack}
              valuePropName='checked'
              {...formItemLayout}
            >
              <Checkbox onChange={this.handleStackChange} />
            </Form.Item>

            {(useExistingStack && (
              <Form.Item
                name='stack'
                label={messageTypes[language].select_existing_stack}
                rules={[{ required: true, message: messageTypes[language].field_required }]}
                {...formItemLayout}
              >
                <Select allowClear>
                  {
                    stacks && stacks.length
                      ? stacks.map((stack, n) =>
                        <Option key={n} value={stack.id}>{stack.stack_nr} ({stack.stack_type}) <Tag>{stack.status}</Tag> {stack.description}</Option>
                        )
                      : ''
                  }
                </Select>
              </Form.Item>
            ))}
          </>
        )}

        {(!useOverlord || !useExistingStack) && (
          <Form.Item
            name='type'
            label={messageTypes[language].stack_type}
            rules={[{ required: true, message: messageTypes[language].field_required }]}
            {...formItemLayout}
          >
            <Select>
              {
                settings.stack_types.length
                  ? settings.stack_types.map((stackType, n) =>
                    <Option key={n} value={stackType}>{stackType}</Option>
                    )
                  : ''
              }
            </Select>
          </Form.Item>)}

        {!useOverlord && (
          <>
            <Form.Item
              name='nr'
              label={messageTypes[language].nr}
              rules={[{ required: true, message: messageTypes[language].field_required }]}
              {...formItemLayout}
            >
              <InputNumber min={0} max={255} />
            </Form.Item>

            <Form.Item
              name='is_ctf'
              label={messageTypes[language].is_ctf}
              valuePropName='checked'
              {...formItemLayout}
            >
              <Checkbox defaultChecked={data.is_ctf || ''} />
            </Form.Item>

            <Form.Item
              name='vmware_user'
              label={messageTypes[language].vmware_user}
              rules={[{ required: true, message: messageTypes[language].field_required }]}
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='vmware_password'
              label={messageTypes[language].vmware_password}
              rules={[{ required: isNewRecord || !data.vmware_password, message: messageTypes[language].field_required }]}
              {...formItemLayout}
            >
              <Input.Password placeholder={data.vmware_password && '***********'} />
            </Form.Item>

            <Form.Item
              name='vmware_admin_user'
              label={messageTypes[language].vmware_admin_user}
              {...formItemLayout}
            >
              <Input />
            </Form.Item>
            <Form.Item
              name='vmware_admin_password'
              label={messageTypes[language].vmware_admin_password}
              {...formItemLayout}
            >
              <Input.Password placeholder={data.vmware_admin_password && '***********'} />
            </Form.Item>

          </>)}

        <Form.Item
          name='demo'
          label={messageTypes[language].demo}
          {...formItemLayout}
        >
          <Select allowClear>
            {
              settings.demo_types && settings.demo_types.length
                ? settings.demo_types.map((demoType, n) =>
                  <Option key={n} value={demoType}>{demoType}</Option>
                  )
                : ''
            }
          </Select>
        </Form.Item>

        <Form.Item className='form-footer'>
          <Divider />
          <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
          <Button onClick={() => { this.props.onCancel() }} className='cancel'>{messageTypes[language].cancel}</Button>
        </Form.Item>
      </Form>
    )
  }
}

export default StyledStackForm

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { machinesGet } from '../../../actions'
import { namesMatch, openNotificationPopup, getCookie } from '../../../actions/helpers'
import ConsoleEmulator from './consoleEmulator'
import StyledMachines from '../../../styledComponents/sections/machines/'
import StyledLoader from '../../../styledComponents/common/loader'
import { messageTypes } from '../../../actions/messages'
import settings from '../../../config/'
import { setStackActionStatus } from '../../../store/actions'

class Machines extends Component {
  constructor () {
    super()
    this.state = {
      machines: [],
      matchMachines: [],
      machineId: null,
      consoleOpen: false,
      allowStateStatus: []
    }
  }

   setAllowStateStatus = (vmUuid) => {
     const allowStateStatus = this.state.allowStateStatus
     allowStateStatus[vmUuid] = true
     this.setState({ allowStateStatus })
   }

   clearAllowStateStatus = (vmUuid) => {
     const allowStateStatus = this.state.allowStateStatus

     if (allowStateStatus[vmUuid] === true) {
       delete allowStateStatus[vmUuid]

       this.setState({ allowStateStatus })
     }
   }

   getAllMachines = (stackAction = false, vmUuid = null, afterSuccess = null) => {
     const { setStackActionStatus } = this.props

     machinesGet(getCookie('_token')).then((json) => {
       if (this._isMounted || stackAction) {
         if (json.status === 'ok') {
           this.setState({
             machines: json.response,
             matchMachines: json.response
           })

           // if machine id is set, remove allow to set custom status
           if (vmUuid !== null) {
             this.clearAllowStateStatus(vmUuid)
           }

           // stackAction flag means there were whole stack action
           if (stackAction) {
             setStackActionStatus(undefined)
           }

           // if callback after machines get success is set, execute it
           if (afterSuccess !== null) {
             afterSuccess()
           }
         } else if (json.status === 'err') {
           openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
         }
       }
     })
   }

   componentDidMount () {
     this._isMounted = true

     if (settings.ajax_refresh_rate) {
       this.getAllMachines()
       setInterval(() => {
         this.getAllMachines()
       }, settings.ajax_refresh_rate * 1000)
     } else {
       this.getAllMachines()
     }
   }

   componentDidUpdate (prevProps, prevState) {
     const { language } = this.props

     // when switching stack and are loaded machines, clear (hide) machines list
     if (this.props.stack === 'switching' && this.state.machines.length) {
       this.setState({ machines: [], matchMachines: [] })
     }

     // when stack switched, load machines again
     if (prevProps.stack === 'switching' && typeof this.props.stack === 'object') {
       this.getAllMachines()
     }

     // if whole action on stack status changed
     if (this.props.stackActionStatus !== prevProps.stackActionStatus) {
       // refresh machines list when was powered on
       // or if was powered off but powering off from powered on state
       if (this.props.stackActionStatus === 'poweredOn' ||
        (prevProps.stackActionStatus === 'turning_off' && this.props.stackActionStatus === 'poweredOff')) {
         this.getAllMachines(true)
       }
     }

     if (this.props.searchPassphrase !== prevProps.searchPassphrase) {
       if (!this.props.searchPassphrase) {
         this.setState({ matchMachines: this.state.machines })
       } else {
         const matchMachines = []
         const pattern = (this.props.searchPassphrase || '')
         this.state.machines.forEach((val, i) => {
           if ((val.name && namesMatch(pattern, val.name[language])) ||
            (val.desc && namesMatch(pattern, val.desc[language]))) {
             matchMachines.push(val)
           }
         })
         if (this._isMounted) {
           this.setState({ matchMachines })
         }
       }
     }
   }

   componentWillUnmount () {
     this._isMounted = false
   }

  handleConsole = (machineId, consoleOpen) => {
    this.setState({ machineId, consoleOpen })
  }

  render () {
    const { stack } = this.props
    const { machines, matchMachines, machineId, consoleOpen } = this.state

    // find machine by id to get name
    const machine = machineId !== undefined && machineId !== null ? machines.find(x => x.id === machineId) : undefined

    // let machinesFiltered = matchMachines
    // if (!stack.substacks || !stack.substacks.length) {
    //   // if no substacks are defined, show all machines
    //   machinesFiltered = matchMachines
    // } else if (stack !== 'switching' && this.props.currentStackMachines) {
    //   // if substack is not switching and stack machines list is available, show only this substach machines
    //   machinesFiltered = matchMachines.filter(x => this.props.currentStackMachines.includes(x.name.pl.toLowerCase()))
    // } else {
    //   machinesFiltered = []
    // }

    return (
      <div>
        <ConsoleEmulator
          language={this.props.language}
          machineId={machineId}
          machine={machine}
          consoleOpen={consoleOpen}
          onHandleConsole={this.handleConsole}
        />
        <StyledMachines
          machines={matchMachines}
          vpns={this.props.stackVpns}
          language={this.props.language}
          consoleOpen={consoleOpen}
          onHandleConsole={this.handleConsole}
          getAllMachines={this.getAllMachines}
          setAllowStateStatus={this.setAllowStateStatus}
          allowStateStatus={this.state.allowStateStatus}
          stack={stack}
        />
        <StyledLoader fadeOut={machines.length && stack !== 'switching' && true} fadeIn={!machines.length || stack === 'switching'} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  searchPassphrase: state.hdStore.searchPassphrase,
  language: state.hdStore.language,
  stackActionStatus: state.hdStore.stackActionStatus,
  stackVpns: state.hdStore.stackVpns,
  stack: state.hdStore.stack,
  currentStackMachines: state.hdStore.current_stack_machines
})

const mapDispatchToProps = {
  setStackActionStatus
}

const MachinesContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Machines)

export default MachinesContainer

const settings = {
  api_url: "/api",      // API adress, in production use without origin - i.e. "/api"
  languages: ['pl', 'en'],              // All available languages
  installation_type: 'box',             // installation type: [cloud, box]
  default_lang: 'pl',                   // Default app language
  auth_handling: true,                  // Pokaż widok autentykacji
  ajax_refresh_rate: 0,                 // Wyrażony w sekundach, odstęp czasu pomiędzy pobieraniem danych widoku maszyn i scenariuszy
  multistack: true,                     // More than one stack -> Allow assigning Stacks to Users
  registration: false,                   // Is registration form enabled
  scenarios_blocking_enabled: true,
  stacks: [                             // Stacks available for this install
    {'nr': 0, 'title': 'Stack 0', type: 'box'}, // Stack type: [cloud, box]}
    {'nr': 4, 'title': 'Stack 4', type: 'cloud'},
  ],
  stack_types: [                        // Stack types available for this install
    'box', 'cloud', 'recruitment', 'ctf', 'ctf_lab', 'beginners_path'
  ],
  roles: [                              // Roles available for set for users
    {'role': 2, 'name': 'user'},
    {'role': 3, 'name': 'user_recruitment'},
  ],
  networks: [                           // Networks available for this install
    //{'network_name': 'hackgroup-dmz', 'title': 'HackGroup DMZ'},
    {'network_name': 'hackbank-lan', 'title': 'HackBank LAN'},
    //{'network_name': 'hackgroup-malwarelab', 'title': 'HackGroup MalwareLab'},
    //{'network_name': 'hackstock-lan', 'title': 'HackStock LAN'},
    //{'network_name': 'ctf-lab-network', 'title': 'CTF Lab Network'},
    {'network_name': 'attackers-network', 'title': 'Attacker\'s Network'},
    {'network_name': 'management-network', 'title': 'HD Management Network'},
  ] 
}

const views = {
  sa:"fNOvREpuGbsjgG5EXYfFA7J0Ebb2G0Ua",
  ad:"RkGXhJ3L0XrqTd9JSGVtvdRZJ99A6isu",
  us:"iZeephooGaiquo3Quiesh0ooLoh5xeij",
  re:"be8oe6cae9Kaequ5Gahsiexoobeequey"
}

export default settings;
export { views }

import React, { Component } from 'react'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../../actions/messages'
import { Tooltip } from 'antd'

class StyledRevertButton extends Component {
  render () {
    const revertDisabled = !this.props.revert

    return (
      <Tooltip placement='top' title={revertDisabled ? messageTypes[this.props.language].revert_is_disabled : ''}>
        <div
          onClick={() => revertDisabled ? null : this.props.handleClick(this.props.id)}
          disabled={revertDisabled}
          className={revertDisabled ? 'vm-action-disabled' : ''}
        >
          <FontAwesomeIcon
            name='undo'
            spin={this.props.status === 'reverting'}
          />
          {messageTypes[this.props.language].revert}
        </div>
      </Tooltip>
    )
  }
}

export default StyledRevertButton

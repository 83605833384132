export const messageTypes = {
  pl: {
    // Tabelki
    scenario: 'Scenariusz',
    description: 'Opis',
    actions: 'Akcje',
    name_name: 'Nazwa',
    role: 'Rola',
    difficulty: 'Poziom trudności',
    machines: 'Maszyny',
    machine_id: 'UUID maszyny',
    answers_split_by_comma: 'Odpowiedzi oddzielone przecinkami',
    back: 'Wstecz',
    time: 'Czas',
    time_hms: 'Czas (GG:MM:SS)',
    data_length: 'Liczba pytań',
    type: 'Typ',
    order: 'Kolejność',
    scenario_type_presentation: 'Prezentacyjny',
    scenario_type_education: 'Edukacyjny',
    scenario_type_recruit: 'Rekrutacyjny',
    scenario_type_hardware: 'Hardware',
    scenario_type_blueteam: 'Blue team',
    machine_name: 'Nazwa maszyny',
    network_name: 'Nazwa sieci',
    event_date: 'Data zdarzenia',
    date: 'Data',
    table_sort_desc: 'Sortuj malejąco',
    table_sort_asc: 'Sortuj rosnąco',
    table_sort_cancel: 'Kliknij aby anulować sortowanie',
    table_pagination_page: '/ strona',

    congrats: 'Gratulacje!',
    congrats2: 'Udało Ci się!',
    all_flags_done: 'Wszystkie flagi zdobyte :-)',
    solve_more: 'Pamiętaj aby zdobyć wszystkie flagi ponieważ punkty mają znaczenie ;-)',

    // universal
    click_to_copy: 'Kliknij aby skopiować',
    copied_to_clipboard: 'Skopiowano do schowka',
    more_info: 'Więcej informacji',
    current: 'bieżące',

    // Kreator scenariuszy
    field_required: '*Pole wymagane',
    name_pl: 'Polska nazwa',
    name_en: 'Angielska nazwa',
    desc_pl: 'Polski opis',
    desc_en: 'Angielski opis',
    desc_long_pl: 'Długi opis pl',
    desc_long_en: 'Długi opis en',
    number_of_files: 'Liczba plików',
    file_label_pl: 'Tytuł pliku pl',
    file_label_en: 'Tytuł pliku en',
    file_type: 'Typ pliku',
    file_path_pl: 'Ścieżka pliku pl',
    file_path_en: 'Ścieżka pliku en',
    file_in_dir: 'plik w katalogu',
    file_path_placeholder: 'np.: %LANG%_filename.pdf',
    active: 'Aktywny',
    s_type: 'Typ scenariusza',
    show: 'Pokaż',
    view_for_roles: 'Widoczne dla ról',
    view_for_stack_types: 'Widoczne dla typów stacka',
    recruitment_scenario: 'Scenariusz rekrutacyjny',
    scenario_demo: 'Wersja demo (brak dost. do materiałów)',
    switch_to_this_stack: 'Przełącz do tego stacka',
    your_current_stack: 'Twój obecny stack',
    scenario_reservable: 'Rezerwowalny przez użytkownika',

    reserved_to: 'Zarezerwowany do',
    reserved_by: 'przez',
    active_reservations: 'Aktywnych rezerwacji',

    // search
    table_search_scenario: 'Szukaj w scenariuszach',
    table_search_desc: 'Szukaj w opisach',
    table_search_name: 'Szukaj w nazwach',
    table_search_email: 'Szukaj w emailach',
    clear: 'Wyczyść',
    save: 'Zapisz',
    quick_guide: 'Rozpocznij',
    instruction: 'Instrukcja',

    edit_profile: 'Edytuj profil',
    new_user: 'Nowy użytkownik',
    new_machine: 'Nowy opis maszyny',
    new_quiz: 'Nowy test',
    new_scenario: 'Nowy scenariusz',

    // user
    user_details: 'Szczegóły użytkownika',
    registration_date: 'Data rejestracji',

    // Vpn
    new_vpn: 'Nowy VPN',
    new_vpn_description: 'Nowy opis VPN',
    vpn_download_err: 'Wystąpił błąd podczas próby pobrania VPN',

    user: 'Użytkownik',
    category: 'Kategoria',
    component: 'Komponent',
    ip_addr: 'Adres IP',
    verbosity_level: 'Poziom informacyjny',
    create_new_stack: 'Stwórz nowy stack',
    switch_stack: 'Zmień środowisko',
    switching_stack: 'Przełączanie na środowisko',
    stack_switch_question: 'Czy na pewno chcesz przełączyć środowisko?',
    stack_switch_poweroff: 'Wyłącz wszystkie maszyny z bieżącego środowiska',
    stack_switch_suspend: 'Uśpij wszystkie maszyny z bieżącego środowiska',
    stack: 'Stack',
    stacks: 'Stacki',
    stack_type: 'Typ stacka',
    stack_types: 'Typy stacka',
    default_stack: 'Domyślny stack',
    stack_unavailable: 'niedostępne',
    stack_suspend_question: 'Czy na pewno chcesz uśpić to środowisko?',
    stack_poweron_question: 'Czy na pewno chcesz włączyć to środwisko?',

    new_stack: 'Nowy stack',
    demo: 'Demo',
    is_ctf: 'CTF',
    select_existing_stack: 'Użyj istniejącego stacka',
    stack_name: 'Nazwa',
    status: 'Status',
    nr: 'Nr',
    used_by_users_count: 'Ilość użytkowników',
    used_by_users: 'Wykorzystywany przez użytkowników',
    vmware_user: 'Nazwa użytkownika VMware',
    vmware_password: 'Hasło użytkownika VMware',
    vmware_admin_user: 'Nazwa użytkownika admin. VMware',
    vmware_admin_password: 'Hasło użytkownika admin. VMware',
    use_overlord: 'Użyj Overlorda',
    overlord_connection_unavailable: 'Połączenie do Overlorda jest aktualnie niedostępne',

    new_stack_description: 'Nowy opis stacka',
    stack_type_description: 'Opis typu stacka',
    ca_certificates: 'Certyfikaty CA',

    // users roles
    role_user: 'Użytkownik',
    role_user_recruitment: 'Użytkownik rekrutacyjny',
    role_user_ctf_ro: 'Użytkownik CTF tylko do odczytu',
    role_admin: 'Admin',

    // Menu
    menu_main_page: 'Strona główna',
    menu_scenarios: 'Scenariusze',
    menu_machines: 'Maszyny',
    menu_ranking: 'Ranking',
    menu_quizzes: 'Testy',
    menu_settings: 'Ustawienia',
    menu_users: 'Użytkownicy',
    menu_sessions: 'Sesje',
    menu_logs: 'Logi',
    menu_vpns: 'VPNy',
    menu_vpn_descriptions: 'Opisy VPNów',
    menu_stack_descriptions: 'Opisy stacków',
    menu_map_objects: 'Obiekty mapy',
    menu_stacks: 'Stacki',

    // map
    new_map_object: 'Nowy obiekt mapy',
    map_id: 'Identyfikator na mapie',
    paste_ctf_flag: 'Wklej flagę CTF...',
    map_legend: 'Legenda mapy',
    map_legend_pl: 'Polska legenda mapy',
    map_legend_en: 'Angielska legenda mapy',

    // Popup
    success: 'Sukces',
    oops: 'Ups',
    server_response: 'Odpowiedź ESXi',
    show_server_response: 'Pokaż odpowiedź ESXi',
    // Quiz popup
    _question: 'Pytanie',
    _question_pl: 'Pytanie pl',
    _question_en: 'Pytanie en',

    _question_name_pl: 'Nazwa pytania PL',
    _question_name_en: 'Nazwa pytania EN',
    _question_desc_pl: 'Opis pytania PL',
    _question_desc_en: 'Opis pytania EN',
    solved: 'Rozwiązane',
    flag: 'Flaga',
    input_flag_please: 'Wpisz flagę w pole!',
    input_answer_please: 'Wpisz rozwiązanie w pole!',
    send: 'Wyślij',
    incorrect_attempts: 'Błędnych prób',
    answered_first_time: 'Odpowiedź poprawna za pierwszym razem',

    _answers: 'Odpowiedzi',
    answer: 'Odpowiedź',
    all_done: 'Zakończono',
    close: 'Zamknij',
    next: 'Następne',
    // Ranking
    name: 'Imię',
    score: 'Punkty',
    score_for_quiz: 'Przyznane punkty za rozwiązanie',
    badges: 'Odznaki',
    place: 'Miejsce',
    edit: 'Edytuj',
    delete: 'Usuń',
    done: 'Zrobione',
    suspend: 'Uśpij',
    suspend_stack: 'Uśpij środowisko',
    refresh: 'Odśwież',
    poweron: 'Włącz',
    wakeup_stack: 'Wzbudź środowisko',

    // Pytania przy podejmowaniu akcji
    short_name: 'PL',
    long_name: 'Polski',
    confirm: 'Tak',
    cancel: 'Anuluj',
    submit: 'Zatwierdź',
    question: 'Jesteś pewien?',
    machine_reboot_question: 'Czy na pewno chcesz zresetować maszynę?',
    machine_revert_question: 'Czy na pewno chcesz przywrócić maszynę?',
    machine_power_question: 'Czy na pewno chcesz wyłączyć maszynę?',

    scenario_revert_question: 'Czy na pewno chcesz zrewertować scenariusz?',
    scenario_test_question: 'Czy na pewno chcesz testować scenariusz?',
    scenario_log_generate_question: 'Czy na pewno chcesz wygenerować logi?',

    quiz_start_question: 'Czy na pewno chcesz rozpocząć ten test?',

    machine_reboot_ok: 'Pomyślnie zrebootowano maszynę',
    machine_revert_ok: 'Pomyślnie zrewertowano maszynę',
    machine_on_ok: 'Pomyślnie włączono maszynę',
    machine_off_ok: 'Pomyślnie wyłączono maszynę',

    machine_reboot_err: 'Wystąpił błąd przy restartowaniu',
    machine_revert_err: 'Wystąpił błąd przy revertowaniu',
    machine_on_err: 'Wystąpił błąd przy włączaniu',
    machine_off_err: 'Wystąpił błąd przy wyłączaniu',
    machine_console_err: 'Wystąpił błąd przy włączaniu konsoli',
    machine_revert_all_question: 'Czy na pewno chcesz zrewertować wszystkie maszyny?',
    machine_setup_new_env_question: 'Czy na pewno chcesz przywrócić środowisko? UWAGA: wszystkie maszyny zostaną przywrócone do oryginalnego stanu.',
    // machine_setup_new_env_question: 'Czy na pewno chcesz przywrócić środowisko? UWAGA: wszystkie maszyny (wszystkie przypisane do Ciebie, nie tylko widoczne te poniżej) zostaną przywrócone do oryginalnego stanu.',
    machine_power_on_all_question: 'Czy na pewno chcesz włączyć wszystkie maszyny?',
    machine_power_off_all_question: 'Czy na pewno chcesz wyłączyć wszystkie maszyny?',
    // "scenario_revert_ok": "Pomyślnie zrewertowano scenariusz",
    // "scenario_test_ok": "Scenariusz pomyślnie przeszedł wszystkie testy",
    // "scenario_log_generate_ok": "Logi pomylnie wygenerowane",

    machine_revert_all_ok: 'Zrewertowano wszystkie maszyny',
    machine_revert_all_err: 'Zrewertowanie wszystkich maszyn nie udało się',

    machine_setup_new_env_ok: 'Przywrócono środowisko',
    machine_setup_new_env_err: 'Przywrócenie środowiska nie udało się',
    machine_power_on_all_ok: 'Włączono wszystkie maszyny',
    machine_power_on_all_err: 'Włączenie wszystkich maszyn nie udało się',
    machine_power_off_all_ok: 'Wyłączono wszystkie maszyny',
    machine_power_off_all_err: 'Wyłączenie wszystkich maszyn nie udało się',

    scenario_on_ok: 'Pomyślnie włączono scenariusz',
    scenario_off_ok: 'Pomyślnie wyłączono scenariusz',
    // Alert powiadomień
    status_poweredOn: 'On',
    status_poweredOff: 'Off',
    status_err: 'Błąd',
    status_turning_on: 'Włączanie',
    status_turning_off: 'Wyłączanie',
    status_rebooting: 'Resetowanie',
    status_reverting: 'Przywracanie',
    status_logging: 'Generowanie logów',
    status_testing: 'Testowanie',
    status_suspended: 'Uśpiona',

    // Nazwy guzików
    no_file: 'Brak w pl',
    download: 'Ściągnij',
    materials: 'Materiały',
    premium_version_only: 'Dostępne w płatnej wersji',
    revert: 'Przywróć',
    revert_all_machines: 'Przywróć wszystkie maszyny',
    setup_new_env: 'Przywróć środowisko',
    power_on_all_machines: 'Włącz wszystkie',
    power_off_all_machines: 'Wyłącz wszystkie',
    log_generate: 'Generuj logi',
    test: 'Testuj',
    reboot: 'Resetuj',
    return: 'Powrót',
    console: 'Konsola',
    ctrl_alt_del: 'Ctrl + Alt + Del',
    fullscreen: 'Tryb pełnoekranowy',
    popup: 'Okno',
    new_window: 'Nowa karta',
    console_is_disabled: 'Konsola jest wyłączona',
    reboot_is_disabled: 'Resetowanie jest wyłączone',
    revert_is_disabled: 'Revert jest wyłączony',
    power_is_disabled: 'Zarządzanie stanem jest wyłączone',

    // Alerty o braku nazwy
    scenario_no_name: 'Brak nazwy PL',
    scenario_no_desc: 'Brak opisu PL',
    scenario_no_desc_long: 'Brak opisu PL',
    machine_no_name: 'Brak nazwy PL',
    machine_no_desc: 'Brak opisu PL',
    machine_name_not_available: 'Maszyna niedostępna',
    machine_desc_not_available: 'Maszyna jest aktualnie niedostępna',
    element_name_not_available_demo: 'Element niedostępny w wersji demo',
    element_desc_not_available_demo: 'Element nie jest dostępny w wersji demo',

    quiz_scenario_no_name_specified: 'Należy wybrać scenariusz!',
    quiz_scenario_no_desc_pl: 'Należy wpisać polski opis!',
    quiz_no_seconds: 'Należy wpisać liczbę sekund!',
    quiz_no_score: 'Należy wpisać wynik za rozwiązanie!',
    quiz_at_least_1_question: 'Liczba musi być większa od 1',
    number_of_questions: 'Liczba pytań',

    quiz_desc_pl: 'Polski opis',
    quiz_desc_en: 'Angielski opis',
    quiz_time_seconds: 'Czas na wykonanie',
    seconds: 'Sekundy',

    user_quiz_details: 'Szczegóły testu',
    quizzes_in_progress: 'Testy w trakcie rozwiązywania',
    quizzes_in_progress_empty: 'Brak rozpoczętych testów',
    quizzes_solved: 'Testy rozwiązane',
    quizzes_solved_empty: 'Brak rozwiązanych testów',
    quiz_started_at: 'Data rozpoczęcia',
    quiz_solved_at: 'Data ukończenia',

    // Common
    tags: 'Tagi',
    scenario_usage: 'Zużycie zasobów',
    scenario_machines_status: 'Status maszyn',
    search: 'Szukaj',
    certificates: 'Certyfikaty',

    // vpns
    vpns: 'VPNy',
    download_conf: 'Pobierz konfigurację',

    // Login and register
    login: 'Logowanie',
    registration: 'Rejestracja',
    register_now: 'Zarejestruj się!',
    reset_password_link: 'Resetuj hasło',
    reset_password: 'Resetowanie hasła',
    back_to_login: 'Wróć do strony logowania',
    reset_password_action: 'Resetuj hasło',
    enter_email_used_during_registration: 'Podaj email użyty podczas rejestracji',
    set_new_password: 'Ustaw nowe hasło',

    register: 'Zarejestruj',
    log_in: 'Zaloguj',
    logout: 'Wyloguj',
    profile: 'Profil',

    login_name: 'Użytkownik',
    login_email: 'E-mail',
    login_password: 'Hasło',
    login_password_repeat: 'Powtórz hasło',
    login_current_password: 'Obecne hasło',
    login_new_password: 'Nowe hasło',

    // Validators
    login_name_err: 'Musisz wpisać nazwę użytkownika!',
    login_email_err: 'Musisz wpisać adres e-mail!',
    login_password_err: 'Musisz wpisać hasło!',
    login_password_repeat_err: 'Musisz wpisać ponownie hasło!',
    login_password_repeat_no_match_err: 'Hasła się nie zgadzają!',

    // logs
    log_details: 'Szczegóły logu',

    // sessions
    sessions_iat: 'Data zalogowania',
    sessions_exp: 'Data wygaśnięcia',

    // scenarios blocking
    reservation_cancel: 'Anuluj rezerwację',
    book_1_day: 'Rezerwuj na 1 dzień',
    book_2_day: 'Rezerwuj na 2 dni',
    book_3_day: 'Rezerwuj na 3 dni',
    extend_1_day: 'Przedłuż o 1 dzień',
    extend_2_day: 'Przedłuż o 2 dni',
    extend_3_day: 'Przedłuż o 3 dni'
  },
  en: {
    // Table
    scenario: 'Scenario',
    description: 'Description',
    actions: 'Actions',
    name_name: 'Name',
    role: 'Role',
    difficulty: 'Difficulty',
    machines: 'Machines',
    machine_id: 'Machine UUID',
    answers_split_by_comma: 'Answers separated by comma',
    back: 'Back',
    time: 'Time',
    time_hms: 'Time (HH:MM:SS)',
    data_length: 'Number of questions',
    type: 'Type',
    order: 'Order',
    scenario_type_presentation: 'Presentation',
    scenario_type_education: 'Educational',
    scenario_type_recruit: 'Recruiting',
    scenario_type_hardware: 'Hardware',
    scenario_type_blueteam: 'Blue team',
    machine_name: 'Machine name',
    network_name: 'Network name',
    event_date: 'Event date',
    date: 'Date',
    table_sort_desc: 'Sort descending',
    table_sort_asc: 'Sort ascending',
    table_sort_cancel: 'Click to cancel sorting',
    table_pagination_page: '/ page',

    congrats: 'Congratulations!',
    congrats2: 'MISSION COMPLETED, You did it!',
    all_flags_done: 'All flags done :-)',
    solve_more: 'Although, remember that score matters at the end. ;-)',

    // universal
    click_to_copy: 'Click to copy',
    copied_to_clipboard: 'Copied to clipboard',
    more_info: 'More info',
    current: 'current',

    // Kreator scenariuszy
    field_required: '* Field required',
    name_pl: 'Polish name',
    name_en: 'English name',
    desc_pl: 'Polish description',
    desc_en: 'English description',
    desc_long_pl: 'Long desc pl',
    desc_long_en: 'Long desc en',
    number_of_files: 'File number',
    file_label_pl: 'Polish file title',
    file_label_en: 'English file title',
    file_type: 'File type',
    file_path_pl: 'Polish filepath',
    file_path_en: 'English filepath',
    file_in_dir: 'file in directory',
    file_path_placeholder: 'eg.: %LANG%_filename.pdf',
    active: 'Active',
    s_type: 'Scenario type',
    show: 'Show',
    view_for_roles: 'Visible for roles',
    view_for_stack_types: 'Visible for stack types',
    recruitment_scenario: 'Recruitment scenario',
    scenario_demo: 'Demo version (no access to materials)',
    switch_to_this_stack: 'Switch to this stack',
    your_current_stack: 'Your current stack',
    scenario_reservable: 'Reservable by user',

    reserved_to: 'Reserved to',
    reserved_by: 'by',
    active_reservations: 'Active reservations',

    // search
    table_search_scenario: 'Search in scenario',
    table_search_desc: 'Search descriptions',
    table_search_name: 'Search in names',
    table_search_email: 'Search in emails',
    clear: 'Clear',
    save: 'Save',
    quick_guide: 'Quick guide',
    instruction: 'Instruction',

    edit_profile: 'Edit profile',
    new_user: 'New user',
    new_machine: 'New machine description',
    new_quiz: 'New quiz',
    new_scenario: 'New scenario',

    // user
    user_details: 'Details of user',
    registration_date: 'Registration date',

    // Vpn
    new_vpn: 'New VPN',
    new_vpn_description: 'New VPN description',
    vpn_download_err: 'There was a problem downloading VPN',

    user: 'User',
    category: 'Category',
    component: 'Component',
    ip_addr: 'IP address',
    verbosity_level: 'Verbosity',
    create_new_stack: 'Create new stack',
    switch_stack: 'Change environment',
    switching_stack: 'Changing environment to',
    stack_switch_question: 'Are you sure to switch environment?',
    stack_switch_poweroff: 'Power off all machines from current environment',
    stack_switch_suspend: 'Suspend all machines from current environment',
    stack: 'Stack',
    stacks: 'Stacks',
    stack_type: 'Stack type',
    stack_types: 'Stack types',
    default_stack: 'Default stack',
    stack_unavailable: 'unavailable',
    stack_suspend_question: 'Are you sure to suspend this environment?',
    stack_poweron_question: 'Are you sure to power on this environment?',

    new_stack: 'New stack',
    demo: 'Demo',
    is_ctf: 'CTF',
    select_existing_stack: 'Use existing stack',
    stack_name: 'Name',
    status: 'Status',
    nr: 'Nr',
    used_by_users_count: 'Users count',
    used_by_users: 'Used by users',
    vmware_user: 'VMware username',
    vmware_password: 'VMware password',
    vmware_admin_user: 'VMware admin username',
    vmware_admin_password: 'VMware admin password',
    use_overlord: 'Use Overlord',
    overlord_connection_unavailable: 'Connection to Overlord is currently unavailable',

    new_stack_description: 'New stack description',
    stack_type_description: 'Description of stack type',
    ca_certificates: 'CA certificates',

    // users roles
    role_user: 'User',
    role_user_recruitment: 'Recruitment user',
    role_user_ctf_ro: 'User CTF view only',
    role_admin: 'Admin',

    // Menu
    menu_main_page: 'Home page',
    menu_scenarios: 'Scenarios',
    menu_machines: 'Machines',
    menu_ranking: 'Ranking',
    menu_quizzes: 'Tests',
    menu_settings: 'Settings',
    menu_users: 'Users',
    menu_sessions: 'Sessions',
    menu_logs: 'Logs',
    menu_vpns: 'VPNs',
    menu_vpn_descriptions: 'VPN descript.',
    menu_stack_descriptions: 'Stack desc.',
    menu_map_objects: 'Map objects',
    menu_stacks: 'Stacks',

    // map
    new_map_object: 'New map object',
    map_id: 'Identifier on map',
    paste_ctf_flag: 'Paste CTF flag...',
    map_legend: 'Map legend',
    map_legend_pl: 'Polish map legend',
    map_legend_en: 'English map legend',

    success: 'Success',
    oops: 'Oops',
    server_response: 'ESXi status',
    show_server_response: 'Show ESXi status',
    _question: 'Question',
    _question_pl: 'Question PL',
    _question_en: 'Question EN',
    _question_name_pl: 'Question name PL',
    _question_name_en: 'Question name EN',
    _question_desc_pl: 'Question desc PL',
    _question_desc_en: 'Question desc EN',
    solved: 'Solved',
    flag: 'Flag',
    input_flag_please: 'Input flag please',
    input_answer_please: 'Input your answer!',
    send: 'Send',
    incorrect_attempts: 'Incorrect attempts',
    answered_first_time: 'Answered properly at first attempt',

    _answers: 'Answers',
    answer: 'Answer',
    all_done: 'Finished',
    close: 'Close',
    next: 'Next',
    // Ranking
    name: 'Name',
    score: 'Score',
    score_for_quiz: 'Score for test',
    badges: 'Badges',
    place: 'Place',
    edit: 'Edit',
    delete: 'Delete',
    done: 'Done',
    suspend: 'Suspend',
    suspend_stack: 'Suspend environment',
    refresh: 'Refresh',
    poweron: 'Power on',
    wakeup_stack: 'Wake up environment',

    // Actions
    short_name: 'EN',
    long_name: 'English',
    confirm: 'Yes',
    cancel: 'Cancel',
    submit: 'Submit',
    question: 'Are you sure?',
    machine_reboot_question: 'Do you want to reboot this machine?',
    machine_revert_question: 'Do you want to revert this machine?',
    machine_power_question: 'Do you want to power off this machine?',

    scenario_revert_question: 'Do you want to revert this scenario?',
    scenario_test_question: 'Do you want to test this scenario?',
    scenario_log_generate_question: 'Do you want to generate logs?',
    machine_revert_all_question: 'Do you want to revert all machines?',
    machine_setup_new_env_question: 'Do you want to revert environment? WARNING: All machines will be restored to original state.',
    // machine_setup_new_env_question: 'Do you want to revert environment? WARNING: All machines (all assigned to you, not only visible below) will be restored to original state.',
    machine_power_on_all_question: 'Do you want to power on all machines?',
    machine_power_off_all_question: 'Do you want to power off all machines?',

    quiz_start_question: 'Do you want to start this test?',

    machine_reboot_ok: 'Machine has been rebooted',
    machine_revert_ok: 'Machine has been reverted',
    machine_on_ok: 'Machine has been turned on',
    machine_off_ok: 'Machine has been turned off',

    machine_reboot_err: 'There was a problem rebooting the machine',
    machine_revert_err: 'There was a problem reverting the machine',
    machine_on_err: 'There was a problem turning on the machine',
    machine_off_err: 'There was a problem turning off the machine',
    machine_console_err: 'There was a problem launching the console',

    scenario_revert_ok: 'Scenario has been reverted',
    scenario_test_ok: 'Tests for this scenario passed successfully',
    scenario_log_generate_ok: 'Scenario logs generated',
    machine_revert_all_ok: 'All machines has been reverted',
    machine_revert_all_err: 'Revert of all machines failed',
    machine_setup_new_env_ok: 'Environment has been reverted',
    machine_setup_new_env_err: 'Revert of environment failed',
    machine_power_on_all_ok: 'All machines has been powered on',
    machine_power_on_all_err: 'Power on of all machines failed',
    machine_power_off_all_ok: 'All machines has been powered off',
    machine_power_off_all_err: 'Power off of all machines failed',

    scenario_on_ok: 'Scenario turned on',
    scenario_off_ok: 'Scenario turned off',

    // Notifications
    status_poweredOn: 'On',
    status_poweredOff: 'Off',
    status_err: 'Error',
    status_turning_on: 'Turning on',
    status_turning_off: 'Turning off',
    status_rebooting: 'Rebooting',
    status_reverting: 'Reverting',
    status_logging: 'Generating logs',
    status_testing: 'Testing',
    status_suspended: 'Suspended',

    // Buttons
    no_file: 'Not avaliable',
    download: 'Download',
    materials: 'Materials',
    premium_version_only: 'Available in premium version',
    revert: 'Revert',
    log_generate: 'Generate logs',
    test: 'Test',
    reboot: 'Reboot',
    return: 'Close',
    revert_all_machines: 'Revert all',
    setup_new_env: 'Revert environment',
    power_on_all_machines: 'Power on all',
    power_off_all_machines: 'Power off all',
    console: 'Console',
    ctrl_alt_del: 'Ctrl + Alt + Del',
    fullscreen: 'Fullscreen',
    popup: 'Popup',
    new_window: 'New tab',
    console_is_disabled: 'Console is disabled',
    reboot_is_disabled: 'Reboot is disabled',
    revert_is_disabled: 'Revert is disabled',
    power_is_disabled: 'State management is disabled',

    // Lack of names and descriptions
    scenario_no_name: 'No name in en',
    scenario_no_desc: 'No description in en',
    scenario_no_desc_long: 'No description in en',
    machine_no_name: 'No name in en',
    machine_no_desc: 'No description in en',
    machine_name_not_available: 'Machine unavailable',
    machine_desc_not_available: 'Machine is currently unavailable',
    element_name_not_available_demo: 'Element unavailable in demo version',
    element_desc_not_available_demo: 'Element is currently unavailable in demo version',

    quiz_scenario_no_name_specified: 'Choose scenario from the list!',
    quiz_scenario_no_desc_pl: 'Specify polish test description',
    quiz_no_seconds: 'Specify number of seconds!',
    quiz_no_score: 'Specify the score!',
    quiz_at_least_1_question: 'Must be greater than 1',
    number_of_questions: 'Questions number',

    quiz_desc_pl: 'Polish descripiton',
    quiz_desc_en: 'English description',
    quiz_time_seconds: 'Time for test',
    seconds: 'Seconds',

    user_quiz_details: 'Details of quiz',
    quizzes_in_progress: 'Quizzes in progress',
    quizzes_in_progress_empty: 'No quizzes in progress found',
    quizzes_solved: 'Solved quizzes',
    quizzes_solved_empty: 'No solved quizzes found',
    quiz_started_at: 'Start date',
    quiz_solved_at: 'Solve date',

    // Common
    scenario_usage: 'Resources usage',
    scenario_machines_status: 'Machines status',
    tags: 'Tags',
    search: 'Search',
    certificates: 'Certificates',

    // vpns
    vpns: 'VPNs',
    download_conf: 'Download configuration',

    // Login and register
    login: 'Login',
    registration: 'Registration',
    register_now: 'Register now!',
    reset_password_link: 'Reset password',
    reset_password: 'Reset password',
    back_to_login: 'Back to login page',
    reset_password_action: 'Reset password',
    enter_email_used_during_registration: 'Enter email used during registration',
    set_new_password: 'Set new password',

    register: 'Register',
    log_in: 'Log in',
    logout: 'Logout',
    profile: 'Profile',

    login_name: 'Name',
    login_email: 'E-mail',
    login_password: 'Password',
    login_password_repeat: 'Repeat password',
    login_current_password: 'Current password',
    login_new_password: 'New password',

    // Validators
    login_name_err: 'Please input your name!',
    login_email_err: 'Please input your e-mail address!',
    login_password_err: 'Please input your password!',
    login_password_repeat_err: 'Please input your password again!',
    login_password_repeat_no_match_err: "Passwords doesn't match!",

    // logs
    log_details: 'Log details',

    // sessions
    sessions_iat: 'Issued at',
    sessions_exp: 'Expires',

    // scenarios blocking
    reservation_cancel: 'Cancel my booking',
    book_1_day: 'Book for 1 day',
    book_2_day: 'Book for 2 days',
    book_3_day: 'Book for 3 days',
    extend_1_day: 'Extend book for 1 day',
    extend_2_day: 'Extend book for 2 days',
    extend_3_day: 'Extend book for 3 days'
  },
  universal: {
    error: 'Error'
  }
}

export const pathNames = [
  {
    name: 'menu_main_page',
    path: '/',
    ico: 'home',
    authOnly: false,
    hideOnCtf: false
  },
  {
    name: 'menu_scenarios',
    path: '/scenarios',
    ico: 'file-protect',
    authOnly: true,
    hideOnCtf: true
  },
  {
    name: 'menu_machines',
    path: '/machines',
    ico: 'cluster',
    authOnly: true,
    hideOnCtf: true
  },
  {
    name: 'menu_ranking',
    path: '/ranking',
    ico: 'bar-chart',
    authUser: true,
    hideOnCtf: false
  },
  {
    name: 'menu_quizzes',
    path: '/tests',
    ico: 'question-circle',
    authOnly: true,
    hideOnCtf: true
  }
]

export const settingPathNames = [
  {
    name: 'menu_users',
    path: '/settings/users',
    ico: 'user',
    authAdmin: true
  },
  {
    name: 'menu_sessions',
    path: '/settings/sessions',
    ico: 'user',
    authAdmin: true
  },
  {
    name: 'menu_stacks',
    path: '/settings/stacks',
    ico: 'cluster',
    authSuperAdmin: true
  },
  {
    name: 'menu_scenarios',
    path: '/settings/scenarios',
    ico: 'file-protect',
    authSuperAdmin: true
  },
  {
    name: 'menu_machines',
    path: '/settings/machines',
    ico: 'cluster',
    authSuperAdmin: true
  },
  {
    name: 'menu_quizzes',
    path: '/settings/tests',
    ico: 'question-circle',
    authSuperAdmin: true
  },
  {
    name: 'menu_logs',
    path: '/settings/logs',
    ico: 'eye',
    authAdmin: true
  },
  {
    name: 'menu_vpns',
    path: '/settings/vpns',
    ico: 'ant-cloud',
    authSuperAdmin: true
  },
  {
    name: 'menu_vpn_descriptions',
    path: '/settings/vpn_descriptions',
    ico: 'file-text',
    authSuperAdmin: true
  },
  {
    name: 'menu_map_objects',
    path: '/settings/map_objects',
    ico: 'profile',
    authSuperAdmin: true
  },
  {
    name: 'menu_stack_descriptions',
    path: '/settings/stack_descriptions',
    ico: 'file-text',
    authSuperAdmin: true
  }
]

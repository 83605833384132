import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button
} from 'antd'
import { Network } from 'vis-network'
import { DataSet } from 'vis-data/peer/esm/vis-data'
import MachineModal from './machineModal'
import VpnModal from './vpnModal'
import MapObjectModal from './mapObjectModal'
import MapNodeModal from './mapNodeModal'
import SubmitFlagForm from '../../../styledComponents/sections/home/submitFlagForm'
import DoneModal from './doneModal'
import StyledLoader from '../../../styledComponents/common/loader'
import { messageTypes } from '../../../actions/messages'
import { openNotificationPopup, getCookie } from '../../../actions/helpers'
import { mapGet, mapSubmitFlag, userStackMapObjectsGet, machinesGet, mapObjectFileDownload, machineFileDownload, userStackVpnDownload } from '../../../actions'
import MapLegendModal from '../../../styledComponents/sections/home/mapLegendModal'
import { InfoCircleOutlined } from '@ant-design/icons'

class Map extends Component {
  constructor (props) {
    super(props)
    this.state = {
      vpnElement: null,
      vpnModalVisible: false,
      mapObjectElement: null,
      mapObjectModalVisible: false,
      machineElement: null,
      machineModalVisible: false,
      vpns: [],
      mapObjects: [],
      machines: [],
      machinesLoaded: false,
      allowMachineStateStatus: {},
      mapNodeElement: null,
      mapNodeModalVisible: false,
      doneModalVisible: false,
      mapLoaded: false,
      doneAllFlags: false,
      map: { edgesData: [], nodesData: [] },
      ctfSubmitFlag: false,
      mapLegendPopupVisible: false
    }
    // create ref for map
    this.network_ref = React.createRef()
    this.last_lang = this.props.language
    this.last_stack = null
    this.network = null
  }

  toggleMachineModal = (flag, machine) => {
    this.setState({ machineElement: machine, machineModalVisible: flag })
  }

  toggleVpnModal = (flag, vpn, machine) => {
    this.setState({ vpnElement: vpn, vpnModalVisible: flag, machineElement: machine })
  }

  toggleMapObjectModal = (flag, mapObject) => {
    this.setState({ mapObjectElement: mapObject, mapObjectModalVisible: flag })
  }

  toggleMapNodeModal = (flag, vpn, machine) => {
    this.setState({ mapNodeElement: vpn, mapNodeModalVisible: flag })
  }

  toggleDoneModal = (flag) => {
    this.setState({ doneModalVisible: flag })
  }

  toggleMapLegendModal = (visible) => {
    this.setState({ mapLegendPopupVisible: visible })
  }

  fitMap = () => {
    const options = {
      animation: {
        duration: 1000,
        easingFunction: 'easeInOutQuad'
      }
    }
    this.network.fit(options)
  }

  copyToClipboard = (str) => {
    const el = document.createElement('textarea')
    el.value = str
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    // show notify
    openNotificationPopup(messageTypes[this.props.language].copied_to_clipboard, str, 'smile')
  }

  isClickableNode = (clickedNode) => {
    // find node by id in all map nodes
    const node = this.state.map.nodesData.find(x => x.id === clickedNode)

    if (node) {
      // determine type of map node
      if (node.type === 'vm') {
        // check if clicked node matches machine name
        const machine = this.state.machines.find(x => x.name.pl.toLowerCase() === clickedNode)
        if (machine) {
          return { entity: machine, type: 'machine' }
        } else {
          return { entity: { disabled: true }, type: 'machine' }
        }
      } else if (node.type === 'vpn') {
        // check if clicked node matches vpn network name
        const vpn = this.props.stackVpns && this.props.stackVpns.find(x => x.network_name === clickedNode)
        if (vpn) {
          return {
            entity: vpn,
            type: 'vpn',
            machine_entity: this.state.machines.find(x => x.name.pl.toLowerCase() === (vpn.vpn_vm_name && vpn.vpn_vm_name.toLowerCase()))
          }
        } else {
          return { entity: { disabled: true }, machine_entity: { disabled: true }, type: 'vpn' }
        }
      } else if (node.type === 'map_object') {
        // check if clicked node matches map object
        const mapObject = this.state.mapObjects.find(x => x.map_id === String(clickedNode))
        if (mapObject) { return { entity: mapObject, type: 'mapObject' } }

        const mapNode = this.state.map.nodesData && this.state.map.nodesData.find(x => x.id === clickedNode)
        if (mapNode && mapNode.flags && mapNode.flags.length > 0) {
          return {
            entity: mapNode,
            type: 'map_node'
          }
        }
      }
    }

    // if no match, node is not clickable
    return false
  }

  buildNetwork () {
    const nodes = new DataSet([])
    const edges = new DataSet([])

    // some paths and map colors
    const imgDirGreen = '/images/icons/green/'
    const imgDirGray = '/images/icons/gray/'
    const imgDirDesert = '/images/icons/desert/'
    const imgDirYellow = '/images/icons/yellow/'
    const imgDirKhaki = '/images/icons/khaki/'
    const green = '#75b52c'
    const red = '#cf4e50'
    const gray = '#8f948f'
    const yellow = '#e2aa00'
    const defaultImageSize = 47

    // legend of nodes ids
    // [string]: vms or vpns with modal on click
    // 1000-2000: network frames or global elements
    // 5001-8000: non-usable and non-clickable nodes, labels etc.
    // 8001-10000: clickable nodes (labels) with copy on click
    let nodesCounter = 5001
    let nodesCounterCopy = 8001

    const nodesData = this.state.map.nodesData
    const edgesData = this.state.map.edgesData

    // add all nodes
    nodesData.forEach((item, i) => {
      if (item.group === 'networkHeader') {
        // if item is network header, set needed separate nodes
        let yOffset = item.y
        nodes.add({ id: ++nodesCounter, x: item.x, y: yOffset, label: item.title, group: 'network_header_title' })
        yOffset += 25
        if (item.subtitle !== undefined) {
          nodes.add({ id: ++nodesCounter, x: item.x, y: yOffset, label: item.subtitle, group: 'network_header_subtitle' })
          yOffset += 25
        }
        // network header may have additional subtitle
        if (item.subtitle2 !== undefined) {
          nodes.add({ id: ++nodesCounter, x: item.x, y: yOffset, label: item.subtitle2, group: 'network_header_subtitle2' })
        }
      } else if (item.group === 'network') {
        const width = item.width
        const height = item.height
        const x = item.x
        const y = item.y

        // calc every line positions
        const cornerTop = { x, y: y - (height / 2) }
        const cornerBottom = { x, y: y + (height / 2) }
        const cornerLeft = { x: x - (width / 2), y }
        const cornerRight = { x: x + (width / 2), y }

        // push lines as nodes
        // top
        nodes.add({
          id: ++nodesCounter,
          group: 'network_corner_horizontal',
          x: cornerTop.x,
          y: cornerTop.y,
          widthConstraint: { minimum: width - 2.8 }
        })
        // bottom
        nodes.add({
          id: ++nodesCounter,
          group: 'network_corner_horizontal',
          x: cornerBottom.x,
          y: cornerBottom.y,
          widthConstraint: { minimum: width - 2.8 }
        })
        // left
        nodes.add({
          id: ++nodesCounter,
          group: 'network_corner_vertical',
          x: cornerLeft.x,
          y: cornerLeft.y,
          heightConstraint: { minimum: height + 1 }
        })
        // right
        nodes.add({
          id: ++nodesCounter,
          group: 'network_corner_vertical',
          x: cornerRight.x,
          y: cornerRight.y,
          heightConstraint: { minimum: height + 1 }
        })
        // if specified set connecting point
        if (item.position === 'bottom') {
          nodes.add({ id: item.id, group: 'network_edge_point', x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y + (item.height / 2) })
        } else if (item.position === 'top') {
          nodes.add({ id: item.id, group: 'network_edge_point', x: (item.point_x !== undefined ? item.point_x : item.x), y: item.y - (item.height / 2) })
        } else if (item.position === 'left') {
          nodes.add({ id: item.id, group: 'network_edge_point', x: item.x - (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        } else if (item.position === 'right') {
          nodes.add({ id: item.id, group: 'network_edge_point', x: item.x + (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        } else if (item.position === 'left_right') {
          // for type left_right set both left and right points with suitable suffix
          nodes.add({ id: item.id + '_left', group: 'network_edge_point', x: item.x - (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
          nodes.add({ id: item.id + '_right', group: 'network_edge_point', x: item.x + (item.width / 2), y: (item.point_y !== undefined ? item.point_y : item.y) })
        }
      } else if (item.group === 'vpn') {
        // prepare offset of node position
        let currentOffset = 48
        // add node
        nodes.add({
          id: item.id,
          group: item.group,
          x: item.x,
          y: item.y,
          title: this.isClickableNode(item.id) ? messageTypes[this.props.language].more_info : undefined
        })
        // add label
        nodes.add({
          id: ++nodesCounter,
          label: item.label,
          group: 'node_label_gray',
          x: item.x,
          y: item.y + currentOffset
        })
        // add ip
        currentOffset += 18
        nodes.add({
          id: ++nodesCounterCopy,
          label: item.ip,
          group: 'node_label_red',
          x: item.x,
          y: item.y + currentOffset,
          title: messageTypes[this.props.language].click_to_copy,
          toCopy: item.network !== undefined ? item.network + item.ip : item.ip
        })
      } else {
        // prepare offset of node position
        let currentOffset = 48
        let group = item.group

        if (group === 'hidden') {
          const hiddenFor = item.id.split('_')[1]

          // search nodes for item hiddenFor, if exists do not add this element on map
          if (nodesData.filter(node => node.id === hiddenFor).length > 0) {
            return
          }
        }

        if (item.flags !== undefined && item.flags.length > 0) {
          if (item.flags_done === 0) {
            group = item.group + '_desert'
          } else if (item.flags_done > 0 && item.flags.length !== item.flags_done) {
            group = item.group + '_desert'
          } else if (item.flags.length === item.flags_done) {
            group = item.group + '_khaki'
          }
        }

        // add node
        nodes.add({
          id: item.id,
          group,
          x: item.x,
          y: item.y,
          title: this.isClickableNode(item.id) ? messageTypes[this.props.language].more_info : undefined
        })
        // add label
        nodes.add({
          id: ++nodesCounter,
          label: item.label,
          group: 'node_label_gray',
          x: item.x,
          y: item.y + currentOffset
        })
        // add sublabel if defined
        if (item.sublabel !== undefined) {
          currentOffset += 17
          nodes.add({
            id: ++nodesCounter,
            label: item.sublabel,
            group: 'node_label_gray',
            x: item.x,
            y: item.y + currentOffset
          })
        }
        // add dns name if defined
        if (item.dns !== undefined) {
          currentOffset += 17
          nodes.add({
            id: ++nodesCounterCopy,
            label: item.dns,
            group: 'node_label_yellow',
            x: item.x,
            y: item.y + currentOffset,
            title: messageTypes[this.props.language].click_to_copy,
            toCopy: item.domain !== undefined ? item.dns + item.domain : item.dns
          })
        }
        // add ip
        if (item.ip !== undefined) {
          currentOffset += 17
          nodes.add({
            id: ++nodesCounterCopy,
            label: item.ip,
            group: item.ip_style !== undefined ? item.ip_style : 'node_label_red',
            x: item.x,
            y: item.y + currentOffset,
            title: messageTypes[this.props.language].click_to_copy,
            toCopy: item.network !== undefined ? item.network + item.ip : item.ip
          })
        }
        // add public dns
        if (item.dns_public !== undefined) {
          if (item.dns !== undefined) currentOffset += 4
          currentOffset += 17
          nodes.add({
            id: ++nodesCounterCopy,
            label: item.dns_public,
            group: 'node_label_green',
            x: item.x,
            y: item.y + currentOffset,
            title: messageTypes[this.props.language].click_to_copy,
            toCopy: item.domain !== undefined ? item.dns_public + item.domain : item.dns_public
          })
        }
        // add public ip
        if (item.ip_public !== undefined) {
          currentOffset += 17
          nodes.add({
            id: ++nodesCounterCopy,
            label: item.ip_public,
            group: item.ip_style !== undefined ? item.ip_style : 'node_label_red',
            x: item.x,
            y: item.y + currentOffset,
            title: messageTypes[this.props.language].click_to_copy,
            toCopy: item.network_public !== undefined ? item.network_public + item.ip_public : item.ip_public
          })
        }
        // add ip2 name if defined
        if (item.ip2 !== undefined) {
          currentOffset += 17
          nodes.add({
            id: ++nodesCounterCopy,
            label: item.ip2,
            group: item.ip2_style !== undefined ? item.ip2_style : 'node_label_gray',
            x: item.x,
            y: item.y + currentOffset,
            title: messageTypes[this.props.language].click_to_copy,
            toCopy: item.network2 !== undefined ? item.network2 + item.ip2 : item.ip2
          })
        }
      }
    })

    // add all edges
    edgesData.forEach((item, i) => {
      if (item.group === 'curve_arrow') {
        // if edge is type of curve, set special edge
        edges.add({
          from: item.from,
          to: item.to,
          smooth: { enabled: true, type: 'curvedCW', roundness: item.roundness ? item.roundness : 0.1 },
          arrows: { to: { enabled: true } }
        })
      } else if (item.group === 'curve_dashed') {
        // if edge is type of curve, set special edge
        edges.add({
          from: item.from,
          to: item.to,
          smooth: { enabled: true, type: 'curvedCW', roundness: item.roundness ? item.roundness : 0.1 },
          dashes: true
        })
      } else if (item.group === 'dashed') {
        // if edge is type of curve, set special edge
        edges.add({
          from: item.from,
          to: item.to,
          dashes: true
        })
      } else {
        // check if connected elements are type of left_right to properly set edge point
        const checkFrom = nodesData.filter(obj => {
          return obj.id === item.from && obj.position === 'left_right'
        })

        const checkTo = nodesData.filter(obj => {
          return obj.id === item.to && obj.position === 'left_right'
        })

        // for default from and to are without left/right modification
        let nodeFrom = item.from
        let nodeTo = item.to

        if (checkFrom.length > 0) {
          nodeFrom += '_right'
        }

        if (checkTo.length > 0) {
          nodeTo += '_left'
        }

        // otherwise simple add edge
        edges.add({ from: nodeFrom, to: nodeTo })
      }
    })

    // create a network
    const data = {
      nodes,
      edges
    }

    // map options
    const options = {
      interaction: {
        hover: true,
        dragNodes: false
      },
      layout: {
        randomSeed: 0
      },
      physics: false,
      nodes: {
        font: {
          color: gray,
          multi: 'markdown'
        },
        labelHighlightBold: false
      },
      edges: {
        width: 2,
        hoverWidth: 0,
        labelHighlightBold: false,
        selectionWidth: 0,
        color: {
          color: green,
          highlight: green,
          hover: green
        }
      },
      groups: {
        // nodes visualisation groups
        network_edge_point: {
          shape: 'box',
          label: ' ',
          heightConstraint: { minimum: 10 },
          widthConstraint: { minimum: 10 },
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          }
        },
        network_corner_horizontal: {
          shape: 'box',
          label: '',
          heightConstraint: { minimum: 1 },
          borderWidth: 0,
          shadow: false,
          shapeProperties: {
            borderRadius: 0
          },
          margin: 0.5,
          color: {
            border: 'transparent',
            background: green,
            highlight: { border: 'transparent', background: green },
            hover: { border: 'transparent', background: green }
          }
        },
        network_corner_vertical: {
          shape: 'box',
          label: '',
          widthConstraint: { minimum: 1 },
          borderWidth: 0,
          shadow: false,
          shapeProperties: {
            borderRadius: 0
          },
          margin: 0.5,
          color: {
            border: 'transparent',
            background: green,
            highlight: { border: 'transparent', background: green },
            hover: { border: 'transparent', background: green }
          }
        },
        node_label_green: {
          shape: 'box',
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            color: green
          }
        },
        node_label_red: {
          shape: 'box',
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            color: red
          }
        },
        node_label_gray: {
          shape: 'box',
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            color: gray
          }
        },
        node_label_yellow: {
          shape: 'box',
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            color: yellow
          }
        },
        firewall_gray: {
          shape: 'image',
          image: imgDirGray + 'firewall_ikonka.svg',
          size: 32
        },
        firewall_yellow: {
          shape: 'image',
          image: imgDirYellow + 'firewall_ikonka.svg',
          size: 28
        },
        firewall_green: {
          shape: 'image',
          image: imgDirGreen + 'firewall_ikonka.svg',
          size: 28
        },
        wifi_gray: {
          shape: 'image',
          image: imgDirGray + 'wifi_ikonka.svg',
          size: 30
        },
        dns: {
          shape: 'image',
          image: imgDirGray + 'dns_ikonka.svg',
          size: 28
        },
        exchange: {
          shape: 'image',
          image: imgDirGreen + 'exchange_server_ikonka.svg',
          size: 32
        },
        domain_controller: {
          shape: 'image',
          image: imgDirGreen + 'domaincontroller_ikonka.svg',
          size: 32
        },
        domain_controller_green: {
          shape: 'image',
          image: imgDirGreen + 'domaincontroller_ikonka.svg',
          size: 32
        },
        domain_controller_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'domaincontroller_ikonka.svg',
          size: 40
        },
        domain_controller_desert: {
          shape: 'image',
          image: imgDirDesert + 'domaincontroller_ikonka.svg',
          size: 40
        },
        vpn: {
          shape: 'image',
          image: imgDirYellow + 'vpn_ikonka.svg',
          size: 32
        },
        hidden: {
          shape: 'image',
          image: imgDirGray + 'hidden.svg',
          size: 29
        },
        server_windows: {
          shape: 'image',
          image: imgDirGreen + 'windows_server_ikonka.svg',
          size: 32
        },
        server_linux: {
          shape: 'image',
          image: imgDirGreen + 'linux_server_ikonka.svg',
          size: 30
        },
        server_linux_green: {
          shape: 'image',
          image: imgDirGreen + 'linux_server_ikonka.svg',
          size: 30
        },
        server_linux_gray: {
          shape: 'image',
          image: imgDirGray + 'linux_server_ikonka.svg',
          size: defaultImageSize
        },
        server_linux_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'linux_server_ikonka.svg',
          size: 30
        },
        server_linux_desert: {
          shape: 'image',
          image: imgDirDesert + 'linux_server_ikonka.svg',
          size: 30
        },
        linux_victim: {
          shape: 'image',
          image: imgDirGreen + 'linux_victim_ikonka.svg',
          size: 33
        },
        linux_victim_green: {
          shape: 'image',
          image: imgDirGreen + 'linux_victim_ikonka.svg',
          size: defaultImageSize
        },
        linux_victim_gray: {
          shape: 'image',
          image: imgDirGray + 'linux_victim_ikonka.svg',
          size: defaultImageSize
        },
        linux_victim_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'linux_victim_ikonka.svg',
          size: 33
        },
        linux_victim_desert: {
          shape: 'image',
          image: imgDirDesert + 'linux_victim_ikonka.svg',
          size: 33
        },
        macos: {
          shape: 'image',
          image: imgDirGreen + 'macos_ikonka.svg',
          size: defaultImageSize
        },
        server_linux_db: {
          shape: 'image',
          image: imgDirGreen + 'linux_server_db_ikonka.svg',
          size: defaultImageSize
        },
        server_linux_router: {
          shape: 'image',
          image: imgDirGreen + 'linux_server_router_ikonka.svg',
          size: defaultImageSize
        },
        server_gray: {
          shape: 'image',
          image: imgDirGray + 'server_ikonka.svg',
          size: defaultImageSize
        },
        server_hd_gray: {
          shape: 'image',
          image: imgDirGray + 'server_hd_ikonka.svg',
          size: 33
        },
        internet_gray: {
          shape: 'image',
          image: imgDirGray + 'publicNetwork_ikonka.svg',
          size: 34
        },
        internet_yellow: {
          shape: 'image',
          image: imgDirYellow + 'publicNetwork_ikonka.svg',
          size: 33
        },
        internet_green: {
          shape: 'image',
          image: imgDirGreen + 'publicNetwork_ikonka.svg',
          size: 33
        },
        pc_gray: {
          shape: 'image',
          image: imgDirGray + 'pc_ikonka.svg',
          size: defaultImageSize
        },
        pc_hd_gray: {
          shape: 'image',
          image: imgDirGray + 'pc_hd_ikonka.svg',
          size: 36
        },
        pc_windows: {
          shape: 'image',
          image: imgDirGreen + 'windows_ikonka.svg',
          size: 33
        },
        pc_windows_green: {
          shape: 'image',
          image: imgDirGreen + 'windows_ikonka.svg',
          size: defaultImageSize
        },
        pc_windows_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'windows_ikonka.svg',
          size: 34
        },
        pc_windows_desert: {
          shape: 'image',
          image: imgDirDesert + 'windows_ikonka.svg',
          size: 34
        },
        pc_windows7_gray: {
          shape: 'image',
          image: imgDirGray + 'windows7_ikonka.svg',
          size: defaultImageSize
        },
        pc_windows_victim: {
          shape: 'image',
          image: imgDirGreen + 'windows_victim_ikonka.svg',
          size: defaultImageSize
        },
        pc_windows_victim_green: {
          shape: 'image',
          image: imgDirGreen + 'windows_victim_ikonka.svg',
          size: defaultImageSize
        },
        pc_windows_victim_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'windows_victim_ikonka.svg',
          size: 35
        },
        pc_windows_victim_desert: {
          shape: 'image',
          image: imgDirDesert + 'windows_victim_ikonka.svg',
          size: 35
        },
        pc_windows_wifi_victim: {
          shape: 'image',
          image: imgDirGreen + 'windows_victim_wifi_ikonka.svg',
          size: defaultImageSize
        },
        pc_attacker: {
          shape: 'image',
          image: imgDirGreen + 'kali_ikonka.svg',
          size: defaultImageSize
        },
        hacker: {
          shape: 'image',
          image: imgDirGreen + 'hacker_ikonka.svg',
          size: 32
        },
        hacker_green: {
          shape: 'image',
          image: imgDirGreen + 'hacker_ikonka.svg',
          size: 32
        },
        hacker_gray: {
          shape: 'image',
          image: imgDirGray + 'hacker_ikonka.svg',
          size: 32
        },
        hacker_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'hacker_ikonka.svg',
          size: 32
        },
        hacker_desert: {
          shape: 'image',
          image: imgDirDesert + 'hacker_ikonka.svg',
          size: 32
        },
        pc_attacker_green: {
          shape: 'image',
          image: imgDirGreen + 'kali_ikonka.svg',
          size: defaultImageSize
        },
        pc_attacker_gray: {
          shape: 'image',
          image: imgDirGray + 'kali_ikonka.svg',
          size: 45
        },
        pc_attacker_yellow: {
          shape: 'image',
          image: imgDirYellow + 'kali_ikonka.svg',
          size: defaultImageSize
        },
        printer: {
          shape: 'image',
          image: imgDirGreen + 'drukarka_ikonka.svg',
          size: 32
        },
        printer_green: {
          shape: 'image',
          image: imgDirGreen + 'drukarka_ikonka.svg',
          size: 32
        },
        printer_khaki: {
          shape: 'image',
          image: imgDirKhaki + 'drukarka_ikonka.svg',
          size: 32
        },
        printer_desert: {
          shape: 'image',
          image: imgDirDesert + 'drukarka_ikonka.svg',
          size: 32
        },
        pc_wifi_attacker: {
          shape: 'image',
          image: imgDirGreen + 'kali_wifi_ikonka.svg',
          size: defaultImageSize
        },
        ctf: {
          shape: 'image',
          image: imgDirGreen + 'ctf_ikonka.svg',
          size: 33
        },
        switch_gray: {
          shape: 'image',
          image: imgDirGray + 'switch_ikonka.svg',
          size: 32
        },
        bts_gray: {
          shape: 'image',
          image: imgDirGray + 'gsmbts_ikonka.svg',
          size: 34
        },
        acs_gray: {
          shape: 'image',
          image: imgDirGray + 'skd_ikonka.svg',
          size: 32
        },
        esxi_gray: {
          shape: 'image',
          image: imgDirGray + 'esxi_server_ikonka.svg',
          size: 33
        },
        network_header_title: {
          shape: 'box',
          shapeProperties: {
            borderRadius: 0
          },
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            size: 18,
            color: green
          },
          value: 1
        },
        network_header_subtitle: {
          shape: 'box',
          shapeProperties: {
            borderRadius: 0
          },
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            color: yellow
          },
          value: 1
        },
        network_header_subtitle2: {
          shape: 'box',
          shapeProperties: {
            borderRadius: 0
          },
          color: {
            border: 'transparent',
            background: 'transparent',
            highlight: { border: 'transparent', background: 'transparent' },
            hover: { border: 'transparent', background: 'transparent' }
          },
          labelHighlightBold: false,
          font: {
            color: red
          },
          value: 1
        }
      }
    }

    const _this = this

    // map initialization
    this.network = new Network(this.network_ref.current, data, options)

    // on right mouse click, fit map to window
    this.network.on('oncontext', function (params) {
      params.event.preventDefault()
      _this.fitMap()
    })

    this.network.on('selectNode', function (params) {
      if (params.nodes.length > 0) {
        const clickedNode = params.nodes[0]
        const clickedNodeObject = nodes.get(clickedNode)
        const clickedElement = _this.isClickableNode(clickedNode)

        // not show modal on non-string nodes and on vpn nodes
        if (clickedElement) {
          if (clickedElement.type === 'vpn') {
            _this.toggleVpnModal(true, clickedElement.entity, clickedElement.machine_entity)
          } else if (clickedElement.type === 'mapObject') {
            _this.toggleMapObjectModal(true, clickedElement.entity)
            // _this.modalPopup(clickedNode);
          } else if (clickedElement.type === 'machine') {
            // _this.toggleMapObjectModal(true, clickedNode);
            _this.toggleMachineModal(true, clickedElement.entity)
          } else if (clickedElement.type === 'map_node') {
            _this.toggleMapNodeModal(true, clickedElement.entity)
          }
        } else if (clickedNode > 8000 && clickedNode < 9000) {
          // nodes with id 8000-9000 are labels with text copy on click
          const toCopy = clickedNodeObject.toCopy
          // if things to copy are specified, copy to clipboard
          if (toCopy !== undefined) {
            _this.copyToClipboard(toCopy)
          }
        }
      }
      // unselect all nodes to allow select node again
      _this.network.unselectAll()
    })

    // canvas handle is needed to allow cursor changing
    const networkCanvas = this.network_ref.current.getElementsByTagName('canvas')[0]

    // simple function to change cursor over node
    function changeCursor (newCursorStyle) {
      networkCanvas.style.cursor = newCursorStyle
    }

    // hover node event
    this.network.on('hoverNode', function (params) {
      if (params.node) {
        const clickedNode = params.node

        if (_this.isClickableNode(clickedNode)) {
          changeCursor('pointer')
        } else if (clickedNode > 8000 && clickedNode < 9000) {
          changeCursor('pointer')
        }
      }
    })

    // blur node event
    this.network.on('blurNode', function (params) {
      changeCursor('default')
    })

    this.setState({ mapLoaded: true })
  }

  submitFlag = (flag) => {
    mapSubmitFlag(flag, getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        if (json.all_done || json.gold_flag) {
          this.toggleDoneModal(true)
          this.setState({ doneAllFlags: json.all_done })
        }

        openNotificationPopup(messageTypes[this.props.language].success, json.response[this.props.language], 'smile')

        this.loadMap()
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  loadMap = () => {
    this.setState({
      map: { nodesData: [], edgesData: [] },
      mapLoaded: false,
      ctfSubmitFlag: false
    })
    if (this.network !== null) {
      this.network.destroy()
    }

    mapGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({
          map: { nodesData: json.response.nodes, edgesData: json.response.edges },
          ctfSubmitFlag: !!json.response.flag_submit_enabled
        })

        this.buildNetwork()
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  getStackMapObjects = () => {
    userStackMapObjectsGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({
          mapObjects: json.response
        })
      } else if (json.status === 'err') {
        openNotificationPopup(
          messageTypes[this.props.language].oops,
          json.response[this.props.language],
          'frown'
        )
      }
    })
  }

  setAllowMachineStateStatus = (vmUuid) => {
    const allowMachineStateStatus = this.state.allowMachineStateStatus
    allowMachineStateStatus[vmUuid] = true
    this.setState({ allowMachineStateStatus })
  }

  clearAllowMachineStateStatus = (vmUuid) => {
    const allowMachineStateStatus = this.state.allowMachineStateStatus

    if (allowMachineStateStatus[vmUuid] === true) {
      delete allowMachineStateStatus[vmUuid]

      this.setState({ allowMachineStateStatus })
    }
  }

  getAllMachines = (stackAction = false, vmUuid = null, afterSuccess = null) => {
    this.setState({
      machines: [],
      machinesLoaded: false
    })
    machinesGet(getCookie('_token')).then((json) => {
      if (json.status === 'ok') {
        this.setState({
          machines: json.response,
          machinesLoaded: true
        })
        // if machine modal was visible during get machines, refresh opened machine object
        if ((this.state.machineModalVisible || this.state.vpnModalVisible) && this.state.machineElement) {
          const machineElement = this.state.machines.find(x => x.id === this.state.machineElement.id)
          this.setState({
            machineElement
          })
        }

        // if machine id is set, remove allow to set custom status
        if (vmUuid !== null) {
          this.clearAllowMachineStateStatus(vmUuid)
        }

        // if callback after machines get success is set, execute it
        if (afterSuccess !== null) {
          afterSuccess()
        }
      } else if (json.status === 'err') {
        openNotificationPopup(messageTypes[this.props.language].oops, json.response[this.props.language], 'frown')
      }
    })
  }

  componentDidMount () {
    // get needed data
    this.getStackMapObjects()
    this.getAllMachines()
    this.loadMap()
  }

  componentDidUpdate () {
    const { stack } = this.props

    if ((!this.last_stack && stack) || (this.last_stack && stack && this.last_stack._id !== stack._id)) {
      this.last_stack = stack

      // get needed data
      this.getStackMapObjects()
      this.getAllMachines()
      this.loadMap()
    }
  }

  render () {
    const { language, stack } = this.props

    // if lang changed build map again
    if (this.last_lang !== language) {
      if (this.network !== null) {
        this.network.destroy()
      }
      // build map only when map element is available
      if (this.network_ref.current !== null) {
        this.buildNetwork()
      }
    }

    // remember lang
    this.last_lang = language

    return (
      <>
        <MachineModal
          language={this.props.language}
          visible={this.state.machineModalVisible}
          element={this.state.machineElement}
          machineElement={this.state.machineElement}
          toggleModal={this.toggleMachineModal}
          getAllMachines={this.getAllMachines}
          setAllowStateStatus={this.setAllowMachineStateStatus}
          allowStateStatus={this.state.allowMachineStateStatus}
          downloadFile={machineFileDownload}
          stack={stack}
        />
        <VpnModal
          language={this.props.language}
          visible={this.state.vpnModalVisible}
          element={this.state.vpnElement}
          machineElement={this.state.machineElement}
          toggleModal={this.toggleVpnModal}
          getAllMachines={this.getAllMachines}
          setAllowStateStatus={this.setAllowMachineStateStatus}
          allowStateStatus={this.state.allowMachineStateStatus}
          downloadFile={userStackVpnDownload}
          stack={stack}
        />
        <MapObjectModal
          language={this.props.language}
          visible={this.state.mapObjectModalVisible}
          element={this.state.mapObjectElement}
          toggleModal={this.toggleMapObjectModal}
          downloadFile={mapObjectFileDownload}
        />
        <MapNodeModal
          language={this.props.language}
          visible={this.state.mapNodeModalVisible}
          element={this.state.mapNodeElement}
          toggleModal={this.toggleMapNodeModal}
        />
        <DoneModal
          language={this.props.language}
          visible={this.state.doneModalVisible}
          toggleModal={this.toggleDoneModal}
          doneAllFlags={this.state.doneAllFlags}
        />
        {stack && stack.map_legend && stack.map_legend[language]
          ? (
            <Button type='primary' onClick={() => { this.toggleMapLegendModal(true) }} className='map-legend-button'>
              <InfoCircleOutlined />{messageTypes[language].map_legend}
            </Button>)
          : ''}
        {this.state.ctfSubmitFlag === true
          ? (
            <SubmitFlagForm
              language={this.props.language}
              submitFlag={this.submitFlag}
            />)
          : ''}
        <MapLegendModal
          language={language}
          visible={this.state.mapLegendPopupVisible}
          handleMapLegendVisibility={this.toggleMapLegendModal}
        />
        <div ref={this.network_ref} className={`map ${this.state.machinesLoaded && this.state.mapLoaded && stack !== 'switching' ? 'fade-in' : ''}`} />
        <StyledLoader fadeOut={this.state.machinesLoaded && this.state.mapLoaded && true && stack !== 'switching'} />
      </>
    )
  }
}

const mapStateToProps = state => ({
  language: state.hdStore.language,
  auth: state.hdStore.auth,
  stackVpns: state.hdStore.stackVpns,
  stack: state.hdStore.stack
})

const MapContainer = connect(
  mapStateToProps
)(Map)

export default MapContainer

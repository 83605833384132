export const setLanguage = language => ({
  type: 'SET_LANGUAGE',
  language
})

export const setSearchPassphrase = searchPassphrase => ({
  type: 'SET_SEARCH_PASSPHRASE',
  searchPassphrase
})

export const setScenarioElement = scenarioElement => ({
  type: 'SET_SCENARIO_ELEMENT',
  scenarioElement
})

export const setScenarioModalVisible = scenarioModalVisible => ({
  type: 'SET_SCENARIO_MODAL_VISIBLE',
  scenarioModalVisible
})

export const setStackActionStatus = stackActionStatus => ({
  type: 'SET_STACK_ACTION_STATUS',
  stackActionStatus
})

export const setQuizId = quizId => ({
  type: 'SET_QUIZ_ID',
  quizId
})

export const setUiPathname = uiPathname => ({
  type: 'SET_UI_PATHNAME',
  uiPathname
})

export const setAuth = auth => ({
  type: 'SET_AUTH',
  auth
})

export const setUser = user => ({
  type: 'SET_USER',
  user
})

export const setStackVpns = stackVpns => ({
  type: 'SET_STACK_VPNS',
  stackVpns
})

export const setStack = stack => ({
  type: 'SET_STACK',
  stack
})

export const setUserStacks = userStacks => ({
  type: 'SET_USER_STACKS',
  userStacks
})

export const setCurrentStackType = currentStackType => ({
  type: 'SET_CURRENT_STACK_TYPE',
  currentStackType
})

export const setCurrentStackMachines = currentStackMachines => ({
  type: 'SET_CURRENT_STACK_MACHINES',
  currentStackMachines
})

import React from 'react'
import { connect } from 'react-redux'
import { /* setCurrentStackMachines, */ setStack } from '../../store/actions'
import StyledStackSwitchDropdown from '../../styledComponents/common/stackSwitchDropdown'
import StackSwitchComponent from './stackSwitchComponent'

class StackSwitchDropdown extends StackSwitchComponent {
  render () {
    const { stack, language, userStacks } = this.props
    const { switching, switching_to: switchingTo } = this.state

    return (
      <StyledStackSwitchDropdown
        stacks={userStacks}
        switching={switching}
        switchingTo={switchingTo}
        currentStackId={stack && typeof stack === 'object' && stack._id}
        language={language}
        switchStack={this.switchStack}
      />
    )
  }
}

const mapStateToProps = state => ({
  stack: state.hdStore.stack,
  userStacks: state.hdStore.user_stacks
})

const mapDispatchToProps = {
  // setCurrentStackMachines,
  setStack
}

const StackSwitchDropdownContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(StackSwitchDropdown)

export default StackSwitchDropdownContainer

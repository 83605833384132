import React from 'react'
import { messageTypes } from '../../../actions/messages'
import {
  Form, Input, Button, Row, Col
} from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

class SubmitFlagForm extends React.Component {
  handleSubmit = (values) => {
    this.props.submitFlag(values.flag)
  }

  render () {
    const { language, loading } = this.props

    return (
      <Form onFinish={this.handleSubmit} layout='horizontal'>
        <div className='ctf-flag'>
          <Row>
            <Col span={16}>
              <Form.Item
                name='flag'
                rules={[{ required: true, message: messageTypes[language].field_required }]}
              >
                <Input placeholder={messageTypes[language].paste_ctf_flag} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Button htmlType='submit'>{loading ? <LoadingOutlined /> : ''} {messageTypes[language].submit}</Button>
            </Col>
          </Row>
        </div>
      </Form>
    )
  }
}

export default SubmitFlagForm

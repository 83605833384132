import React from 'react'
import { Layout, Input, Menu, Button, Tag } from 'antd'
import { QuestionCircleOutlined, UserOutlined, FlagOutlined, LogoutOutlined, SolutionOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { messageTypes } from '../../actions/messages'
import { Link } from 'react-router-dom'
import settings from '../../config/'
import CertificateDropdown from './certificateDropdown'
import VpnDropdown from './vpnDropdown'
import StackSwitchDropdown from '../../components/common/stackSwitchDropdown'
import HDComponent from '../../components/common/hdComponent'
import FontAwesomeIcon from 'react-fontawesome'

const { Header } = Layout
const SubMenu = Menu.SubMenu
const MenuItemGroup = Menu.ItemGroup

class StyledHeader extends HDComponent {
  render () {
    const { language, uiPathname, auth, machinesNewEnv, handleCADownload, user, stack, stackVpns, userStacks/*, machinesPowerOnAll, machinesPowerOffAll */ } = this.props

    const stackTitle = stack !== 'switching' && stack && stack.name

    const statusOff = stack && stack.status !== 'poweredon' && stack.status !== 'poweringon'
    const statusOn = stack && (stack.status === 'poweredon' || stack.status === 'poweringon')

    return (
      <>
        <Header style={{ background: '#fff', padding: 0 }}>
          <Menu mode='horizontal' selectable={false} style={{ lineHeight: '64px', textAlign: 'right' }}>
            {(uiPathname === '/' || uiPathname === '/machines' || uiPathname === '/scenarios' || uiPathname === '/tests') &&
              settings.auth_handling && auth && userStacks && userStacks.length > 1 &&
              (
                <Menu.Item className='menu-top-left'><StackSwitchDropdown language={language} /></Menu.Item>
              )}

            {(uiPathname === '/' || uiPathname === '/machines') && stack && stack.ca_certificates && !!stack.ca_certificates.length &&
              (
                <Menu.Item className='menu-top-left'>
                  <CertificateDropdown language={language} handleCADownload={handleCADownload} certificates={stack.ca_certificates} />
                </Menu.Item>
              )}

            {(uiPathname === '/' || uiPathname === '/machines') && settings.auth_handling && auth && stackVpns && stackVpns.length > 0 &&
              <Menu.Item className='menu-top-left'><VpnDropdown language={language} /></Menu.Item>}

            {(uiPathname === '/machines') && settings.auth_handling && auth && stack && stack.revert &&
              [
                <Menu.Item className='menu-top-left' key='setup_new_env'>
                  <Button onClick={() => { this.props.handleNewEnv() }}>
                    <FontAwesomeIcon name='undo' spin={machinesNewEnv || false} />{messageTypes[language].setup_new_env}
                  </Button>
                </Menu.Item>
              /* Temporary hide power on/off all machines
              <Menu.Item className="menu-top-left" key="power_on_all_machines">
                <Button onClick={() => { this.props.handlePowerOnAllMachines() }}>
                  <FontAwesomeIcon name='power-off' spin={machinesPowerOnAll || false} />{ messageTypes[language]['power_on_all_machines'] }
                </Button>
              </Menu.Item>,
              <Menu.Item className="menu-top-left" key="power_off_all_machines">
                <Button onClick={() => { this.props.handlePowerOffAllMachines() }}>
                  <FontAwesomeIcon name='power-off' spin={machinesPowerOffAll || false} />{ messageTypes[language]['power_off_all_machines'] }
                </Button>
              </Menu.Item>
              */
              ]}

            {(!settings.auth_handling || auth) && (uiPathname === '/scenarios' || uiPathname === '/machines' || uiPathname === '/settings/logs') &&
              <Menu.Item>
                <Input
                  onChange={(e) => this.props.handleInputChange(e)}
                  placeholder={uiPathname === '/settings/logs' ? messageTypes[language].table_search_desc : messageTypes[language].search}
                />
              </Menu.Item>}

            {(user && user.team_token) &&
              <Menu.Item
                className='team-token'
                onClick={() => { this.props.copyToClipboard(user.team_token, language) }}
                title={messageTypes[language].click_to_copy}
              >
                {messageTypes[language].your_team_token}: {user.team_token}
              </Menu.Item>}

            {(stack && stack.desc && stack.desc[language]) &&
              <Menu.Item>
                <Button type='primary' onClick={() => { this.props.handleQuickGuideVisibility(true) }} className='quick-guide-button'>
                  <QuestionCircleOutlined />{messageTypes[language].quick_guide}
                </Button>
              </Menu.Item>}

            {settings.instruction &&
              <Menu.Item>
                <Button type='primary' onClick={() => { this.props.handleInstructionVisibility(true) }} className='instruction-button'>
                  <InfoCircleOutlined />{messageTypes[language].instruction}
                </Button>
              </Menu.Item>}

            {(settings.auth_handling && !auth) &&
              <Menu.Item className='login-register-link'>
                <Link to='/login'><span>{messageTypes[language].login}</span></Link>
              </Menu.Item>}

            {(settings.auth_handling && !auth && settings.registration) &&
              <Menu.Item className='login-register-link'>
                <Link to='/register'><span>{messageTypes[language].registration}</span></Link>
              </Menu.Item>}

            {
              (settings.auth_handling && auth) &&
                <SubMenu title={
                  <span className='submenu-title-wrapper'>
                    <UserOutlined />{user && user.name}
                  </span>
                }
                >
                  <MenuItemGroup className='user-settings-submenu'>
                    <Menu.Item>
                      <p onClick={() => { this.props.handleProfileVisibility(true) }}>
                        {messageTypes[language].profile} <SolutionOutlined />
                      </p>
                    </Menu.Item>
                    <Menu.Item>
                      <p onClick={() => { this.props.handleLogout() }}>
                        {messageTypes[language].logout} <LogoutOutlined />
                      </p>
                    </Menu.Item>
                  </MenuItemGroup>
                </SubMenu>
            }

            <SubMenu title={
              <span className='submenu-title-wrapper'>
                <FlagOutlined />{messageTypes[language].long_name}
              </span>
            }
            >
              <MenuItemGroup>
                {
                  this.languages.map((val, key) =>
                    <Menu.Item key={key}>
                      <p onClick={() => { this.props.handleClick(val) }}>
                        {messageTypes[val].long_name}
                      </p>
                    </Menu.Item>
                  )
                }
              </MenuItemGroup>
            </SubMenu>
          </Menu>
        </Header>

        {(uiPathname === '/' || uiPathname === '/machines' || uiPathname === '/scenarios' || uiPathname === '/tests') && settings.auth_handling && auth && stack && stackTitle &&
          <>
            <h2 className='stack-title'>
              <span>
                {stackTitle}
              </span>
              {settings.overlord_enabled && stack.status &&
                (
                  <>
                    {statusOff && <Button className='stack-action-button-map' onClick={() => this.props.executeStackAction(stack._id, 'poweron')}>{messageTypes[language].wakeup_stack}</Button>}
                    {statusOn && <Button className='stack-action-button-map' onClick={() => this.props.executeStackAction(stack._id, 'suspend')}>{messageTypes[language].suspend_stack}</Button>}
                    <Tag
                      onClick={() => statusOff ? this.props.executeStackAction(stack._id, 'poweron') : (statusOn ? this.props.executeStackAction(stack._id, 'suspend') : null)}
                      style={{ marginLeft: '10px' }}
                      className={'stack-action' + (statusOff ? ' stack-action-off' : (statusOn ? ' stack-action-on' : ''))}
                      title={(statusOff ? messageTypes[language].wakeup_stack : (statusOn ? messageTypes[language].suspend_stack : null))}
                    />
                  </>
                )}
            </h2>
          </>}
      </>
    )
  }
}

export default StyledHeader

import React, { Component } from 'react'
import FontAwesomeIcon from 'react-fontawesome'
import { messageTypes } from '../../../actions/messages'
import { Tooltip } from 'antd'

class StyledRebootButton extends Component {
  render () {
    const rebootDisabled = this.props.reboot === false || this.props.status !== 'poweredOn'

    return (
      <Tooltip placement='top' title={rebootDisabled ? messageTypes[this.props.language].reboot_is_disabled : ''}>
        <div
          onClick={() => rebootDisabled ? null : this.props.handleClick(this.props.id)}
          disabled={rebootDisabled}
          className={rebootDisabled ? 'vm-action-disabled' : ''}
        >
          <FontAwesomeIcon
            name='power-off'
            pulse={this.props.status === 'rebooting'}
          />
          {messageTypes[this.props.language].reboot}
        </div>
      </Tooltip>
    )
  }
}

export default StyledRebootButton
